import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { SharedService } from 'src/app/pages/helpers/services/shared.service';
import { PacienteAgendaModel, PacienteModel } from 'src/app/pages/pacientes/domain/paciente.model';
import { ProcedimientoConsultaRepository } from 'src/app/pages/procedimientos/application/procedimiento-consulta.repository';
import { ProcedimientoConsultaInfraestructure } from 'src/app/pages/procedimientos/infraestructure/procedimiento-consulta.infraestructure';
import { MenuService } from '../menu/menu.service';
import { StorageRepository } from 'src/app/pages/core/application/storage.repository';

@Component({
  selector: 'app-sidenaved',
  templateUrl: './sidenaved.component.html',
  styleUrls: ['./sidenaved.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavedComponent implements OnInit {

  protected listaPacientesProcedimiento:PacienteModel[]=[]
  private idUsuario:number=0
  public userImage= '../assets/img/users/user.jpg';
  public menuItems:Array<any>;
  public settings: Settings;
  protected pacientes:PacienteModel[]=[]
  constructor(public appSettings:AppSettings, public menuService:MenuService, 
    private _router:Router, protected _pac:ProcedimientoConsultaRepository, 
    private _shared:SharedService,
    protected _storage:StorageRepository
    
    ){
  this.settings = this.appSettings.settings; 
  this.idUsuario=parseInt(this._storage.obtenerDatoSesion('Expi'))
  }
  ngOnInit() {

  let date = new Date()
    this.settings.sidenavedIsOpened=false
    this._pac.getPacientesConProcedimientoNoFiltro(this.idUsuario).subscribe({
      next: (res)=>{
       
     
         this.listaPacientesProcedimiento=res

        
    
      }
    }) 
    
  }

  test(){
    this._router.navigate(['/examen/gestion']); 
  }

  public closeSubMenus(){
    let menu = document.getElementById("vertical-menu");
    if(menu){
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if(child){
          if(child.children[0].classList.contains('expanded')){
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }


  getRecord($row){
  
   console.log($row)

  /*   this._shared.setData($row)
  
    this._router.navigate(['/procedimiento/gestion']);   */

    
    if($row.idPacienteAgenda){
        
    }

    this._pac.getById($row.idPacienteAgenda).subscribe({
      next: (conProc) =>{
              if(conProc){

                if ($row.estado == "Finalizado" || this._storage.obtenerDatoSesion('UC')=="Administrativo" || this._storage.obtenerDatoSesion('UC')=="Admin") {
                  this._shared.setModificable(true);
                }
             
               
                this._shared.setconsultaProc(conProc);
                this._shared.setPac($row)
                this._router.navigate(['/examen/gestion'])
              }else{
                if ($row.estado == "Finalizado" || this._storage.obtenerDatoSesion('UC')=="Administrativo" || this._storage.obtenerDatoSesion('UC')=="Admin") {
                  this._shared.setModificable(true);
                }
                
                this._shared.setData($row)
                this._router.navigate(['/examen/gestion']);
              }
       
      }
    })
   }

  
}
