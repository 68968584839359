import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FomularioUsuarioComponent } from 'src/app/pages/usuarios/page-usuario/components/fomulario-usuario/fomulario-usuario.component';
import { PasswordRepository } from '../../application/change-password.repositorty';
import { NotificacionesService } from 'src/app/pages/helpers/services/notificaciones.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<FomularioUsuarioComponent>,protected _pass:PasswordRepository,protected _notif:NotificacionesService,
    @Inject(MAT_DIALOG_DATA) protected data: any) {

     }

  ngOnInit(): void {
  }

  cancelar(){
    this.dialogRef.close()
  }

  guardar(){


    this._pass.restablecer(this.data.id).subscribe({
      next: (res)=>{
        if(res){
          this._notif.mensajeExito("Contraseña restablecida correctamente","check",5)
          this.dialogRef.close(true)
        }

      },error:(err)=>{
        this._notif.mensajeError("Error al restablecer contraseña","error",5)
      }
    })

  }

}
