import { Prestacion } from "src/app/pages/pacientes/domain/paciente.model";
import { PrestacionRepository } from "../application/prestaciones.repository";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";

@Injectable()
export class PrestacionInfraestructure extends PrestacionRepository {
    
    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

   

    getData(): Observable<Prestacion[]>{
        return this.http.get<Prestacion[]>(`${this.config.appConfig.apiBaseUrl}/Prestacion`).pipe();
    }

    getPrestacionId(prestacionId: number): Observable<Prestacion> {
        return this.http.get<Prestacion>(`${this.config.appConfig.apiBaseUrl}/Prestacion/${prestacionId}`).pipe();
    }

    delete(id: number): Observable<Prestacion>{
        return this.http.delete<Prestacion>(`${this.config.appConfig.apiBaseUrl}/Prestacion/${id}`).pipe()
    }

    update(id: number, data: Partial<Prestacion>): Observable<Prestacion>{
        return this.http.put<Prestacion>(`${this.config.appConfig.apiBaseUrl}/Prestacion/${id}`,data).pipe()
    }

    insert(data: Partial<Prestacion>): Observable<Prestacion>{
        return this.http.post<Prestacion>(`${this.config.appConfig.apiBaseUrl}/Prestacion`,data).pipe()
    }

}