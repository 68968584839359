import { Observable } from "rxjs";
import { Filtro } from "../../helpers/services/filter.service";
import { ReturnModelPaginado } from "../../pacientes/domain/paciente.model";

export abstract class ConsultaRepository {

   abstract getByPageConsulta(page:number,pageSize:number,filter:Filtro[]): Observable<any>
    abstract getDataConsulta(): Observable<any>
    abstract getDataConsultaId(id:number): Observable<any>
    abstract deleteConsulta(id: number): Observable<any>;
    abstract updateConsulta(id: number, data: Partial<any>): Observable<any>;
    abstract insertConsulta(data: Partial<any>): Observable<any>; 
    abstract getPacientesConConsulta(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado>
    abstract insertDerivacion(data: Partial<any>): Observable<any>; 
    abstract getByIdDerivacion(id:number): Observable<any>
    abstract updateDerivacion(id: number, data: Partial<any>): Observable<any>;
    abstract getCie3(): Observable<any>;
    abstract getCie4(cie3:string): Observable<any>;
     
 }