import { Observable } from "rxjs";
import { Filtro } from "../../helpers/services/filter.service";
import { PacienteAgendaModel, PacienteModel, ReturnModelPaginado } from "../domain/paciente.model";
import { IdNombreArchivo } from "../../helpers/interfaces/pdfzip.interface";

export abstract class PacienteRepository {

   abstract getByPagePaciente(page:number,pageSize:number,filter:Filtro[]): Observable<ReturnModelPaginado>
   abstract getByPagePacienteAgenda(page:number,pageSize:number,filter:Filtro[]): Observable<ReturnModelPaginado>
   abstract getByPagePacienteAgendaPrincipal(page:number,pageSize:number,filter:Filtro[],idUsuario:number): Observable<ReturnModelPaginado>
   abstract getDataPaciente(id:number): Observable<PacienteModel>
   abstract getDataPacienteAgenda(idPacienteAgenda:number): Observable<PacienteAgendaModel>
   abstract updatePacienteAgenda(id: number, data: Partial<PacienteAgendaModel>): Observable<PacienteAgendaModel>;
   abstract deletePaciente(id: number): Observable<PacienteModel>;
   abstract updatePaciente(id: number, data: Partial<PacienteModel>): Observable<PacienteModel>;
   abstract insertPaciente(data: Partial<PacienteModel>): Observable<PacienteModel>;
   abstract getPacienteRut(rut:string):Observable<PacienteModel>;
   abstract getHistorialPaciente(idPaciente:number,page:number,pageSize:number): Observable<ReturnModelPaginado>

   abstract descargarPDfPacienteMasivo(listaIdsNombres: IdNombreArchivo[]):Observable<Blob>
    
}