import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UtilizacionService {
  constructor(private http: HttpClient, private config: AppConfigService) {}


  public uso :any
  public cantidadUso:any




  setUso(data){
    this.uso=data
  }

  getUso(){
    return this.uso
  }

  setCantidadEnUso(data){
    this.cantidadUso=data

  }

  getCantidadEnUso(){
    return this.cantidadUso
  }
}
