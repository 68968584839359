import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { InformeConfiguracionRepository } from "../application/informe-config.repository";
import { InformeConfiguracionModel } from "../domain/informe.model";

@Injectable()
export class InformeConfiguracionInfraestructure extends InformeConfiguracionRepository {

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(page?: number, filter?: string): Observable<InformeConfiguracionModel[]>{
        return this.http.get<InformeConfiguracionModel[]>(`${this.config.appConfig.apiBaseUrl}/InformeConfiguracion`).pipe();
    }

    getData(): Observable<InformeConfiguracionModel>{
        return this.http.get<InformeConfiguracionModel>(`${this.config.appConfig.apiBaseUrl}/InformeConfiguracion`).pipe();
    }

    delete(id: number): Observable<InformeConfiguracionModel>{
        return this.http.delete<InformeConfiguracionModel>(`${this.config.appConfig.apiBaseUrl}/InformeConfiguracion/${id}`).pipe()
    }

    update(id: number, data: Partial<InformeConfiguracionModel>): Observable<InformeConfiguracionModel>{
        return this.http.put<InformeConfiguracionModel>(`${this.config.appConfig.apiBaseUrl}/InformeConfiguracion/${id}`,data).pipe()
    }

    insert(data: Partial<InformeConfiguracionModel>): Observable<InformeConfiguracionModel>{
        return this.http.post<InformeConfiguracionModel>(`${this.config.appConfig.apiBaseUrl}/InformeConfiguracion`,data).pipe()
    }

}