import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { ProcedimientoRepository } from "../application/procedimientos.repository";
import { ProcedimientoCamposModel, ProcedimientoModel, ProcedimientoPlantilla } from "../domain/procedimientos.model";

@Injectable()
export class  ProcedimientoInfraestructure extends ProcedimientoRepository  {
  
   
 
    constructor(private http: HttpClient, private config:AppConfigService){
        super();
    }

        //PROCEDIMIENTOS//PROCEDIMIENTOS//PROCEDIMIENTOS//PROCEDIMIENTOS//PROCEDIMIENTOS//PROCEDIMIENTOS//
        getByPageProc(): Observable<ProcedimientoModel[]>{
            return this.http.get<ProcedimientoModel[]>(`${this.config.appConfig.apiBaseUrl}/Procedimiento`).pipe();
        }
        getDataProc(): Observable<ProcedimientoModel>{
            return this.http.get<ProcedimientoModel>(`${this.config.appConfig.apiBaseUrl}/Procedimiento`).pipe();
        }
        deleteProc(id: number): Observable<ProcedimientoModel>{
            return this.http.delete<ProcedimientoModel>(`${this.config.appConfig.apiBaseUrl}/Procedimiento/${id}`).pipe();
        }
        updateProc(id: number, data: Partial<ProcedimientoModel>): Observable<ProcedimientoModel>{
            return this.http.put<ProcedimientoModel>(`${this.config.appConfig.apiBaseUrl}/Procedimiento/${id}`,data).pipe();
        }
        insertProc(data: Partial<ProcedimientoModel>): Observable<ProcedimientoModel>{
            return this.http.post<ProcedimientoModel>(`${this.config.appConfig.apiBaseUrl}/Procedimiento`,data).pipe();
        }
      
   
        //CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS////CAMPOS//
        getByPageCampos(page: number, filter: string): Observable<ProcedimientoCamposModel>{
            return this.http.get<ProcedimientoCamposModel>(`${this.config.appConfig.apiBaseUrl}/Campo`).pipe();
        }
        getDataCampos(): Observable<any>{
            return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Campo`).pipe();
        }
        getDataCamposById(id:number): Observable<any>{
            return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Campo/Single/${id}`).pipe();
        }
        deleteCampos(id: number): Observable<ProcedimientoCamposModel>{
            return this.http.delete<ProcedimientoCamposModel>(`${this.config.appConfig.apiBaseUrl}/Campo/${id}`).pipe();
        }
        updateCampos(id: number, data: Partial<ProcedimientoCamposModel>): Observable<ProcedimientoCamposModel>{
            return this.http.put<ProcedimientoCamposModel>(`${this.config.appConfig.apiBaseUrl}/Campo/${id}`,data).pipe();
        }
        insertCampos(data: Partial<ProcedimientoCamposModel>): Observable<ProcedimientoCamposModel>{
            return this.http.post<ProcedimientoCamposModel>(`${this.config.appConfig.apiBaseUrl}/Campo`,data).pipe();
        }

        getDataCamposProcedimiento(idProcedimiento: number): Observable<ProcedimientoCamposModel[]> {
            return this.http.get<ProcedimientoCamposModel[]>(`${this.config.appConfig.apiBaseUrl}/Campo/${idProcedimiento}`).pipe();
        }
        


        getByPagePlantilla(idProfesional:number): Observable<ProcedimientoPlantilla[]>{
        return this.http.get<ProcedimientoPlantilla[]>(`${this.config.appConfig.apiBaseUrl}/Plantilla/${idProfesional}`).pipe();
        }

        getPlantilla(): Observable<ProcedimientoPlantilla[]>{
            return this.http.get<ProcedimientoPlantilla[]>(`${this.config.appConfig.apiBaseUrl}/Plantilla`).pipe();
            }
        getDataPlantilla(): Observable<ProcedimientoPlantilla>{
            return this.http.get<ProcedimientoPlantilla>(`${this.config.appConfig.apiBaseUrl}/Plantilla`).pipe();
        }
        deletePlantilla(id: number): Observable<ProcedimientoPlantilla>{
            return this.http.delete<ProcedimientoPlantilla>(`${this.config.appConfig.apiBaseUrl}/Plantilla/${id}`).pipe();
        }
        updatePlantilla(id: number, data: Partial<ProcedimientoPlantilla>): Observable<ProcedimientoPlantilla>{
            return this.http.put<ProcedimientoPlantilla>(`${this.config.appConfig.apiBaseUrl}/Plantilla/${id}`,data).pipe();
        }
        insertPlantilla(data: Partial<ProcedimientoPlantilla>): Observable<ProcedimientoPlantilla>{
            return this.http.post<ProcedimientoPlantilla>(`${this.config.appConfig.apiBaseUrl}/Plantilla`,data).pipe();
        } 


}