import { Observable } from "rxjs";
import { Prestacion } from "src/app/pages/pacientes/domain/paciente.model";

export abstract class PrestacionRepository {
  abstract getData(): Observable<Prestacion[]>;
  abstract delete(id: number): Observable<Prestacion>;
  abstract update(
    id: number,
    data: Partial<Prestacion>
  ): Observable<Prestacion>;
  abstract insert(data: Partial<Prestacion>): Observable<Prestacion>;
  abstract getPrestacionId(prestacionId: number): Observable<Prestacion>;
}
