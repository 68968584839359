import { environment } from "src/environments/environment";
import { StorageRepository } from "../application/storage.repository";

import *  as CryptoJS from 'crypto-js';

export class StorageInfraestructure extends StorageRepository {

  constructor(){
    super();
  }


  setStorage(nameProperty: string, value: string): void {
    sessionStorage.setItem(nameProperty, value);
  }
  getStorage(nameProperty: string): string | null {
    return sessionStorage.getItem(nameProperty);
  }
  clear(): void {
    sessionStorage.clear();
  }

  clearAllStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public guardarDatoLocal(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public obtenerDatoLocal(key: string) {
    let data = localStorage.getItem(key)|| "";
    return this.decrypt(data);
  }

  public removerDatoLocal(key: string) {
    localStorage.removeItem(key);
  }

  public limpiarDatosLocal() {
    localStorage.clear();
  }

  public guardarDatoSesion(key: string, value: string) {
    sessionStorage.setItem(key, this.encrypt(value));
  }

  public obtenerDatoSesion(key: string) {
    let data = sessionStorage.getItem(key)|| "";
    return this.decrypt(data);
  }

  public removerDatoSesion(key: string) {
    sessionStorage.removeItem(key);
  }

  public limpiarDatosSesion() {
    sessionStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, environment.SEC_KEY).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, environment.SEC_KEY).toString(CryptoJS.enc.Utf8);
  }




  
}
