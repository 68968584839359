import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { RecetaRepository } from "../application/receta.repository";

@Injectable()
export class RecetaInfraestructure extends RecetaRepository {

 
  constructor(private http: HttpClient,private config:AppConfigService) {
    super();
  }

  getDataRecetaId(id: number): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Receta/${id}`).pipe();
  }

  getDataRecetaConsulta(idConsulta: number): Observable<any> {

      return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Receta/consulta/${idConsulta}`).pipe();
    }

  
  getDataReceta(idConsultaProc:number): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Receta/paciente/${idConsultaProc}`).pipe();
  }
  deleteReceta(id: number): Observable<any> {
    return this.http
      .delete<any>(`${this.config.appConfig.apiBaseUrl}/Receta/${id}`)
      .pipe();
  }
  updateReceta(id: number, data: Partial<any>): Observable<any> {
    return this.http
      .put<any>(`${this.config.appConfig.apiBaseUrl}/Receta/${id}`, data)
      .pipe();
  }
  insertReceta(data: Partial<any>): Observable<any> {
    return this.http.post<any>(`${this.config.appConfig.apiBaseUrl}/Receta/`, data).pipe();
  }



}
