import { Injectable } from "@angular/core";
import { PasswordRepository } from "../application/change-password.repositorty";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";

@Injectable()
export class PasswordInfraestructure extends PasswordRepository {

 
  update(
    id: number,
    data: Partial<any>
  ): Observable<any> {
    return this.http.put<any>(`${this.config.appConfig.apiBaseUrl}/Usuario/cambio/${id}`,data);
  }

  restablecer( id: number):
  Observable<any> {
    return this.http.put<any>(`${this.config.appConfig.apiBaseUrl}/Usuario/restablecer/${id}`,null);
  }

  

  constructor(private http: HttpClient, private config:AppConfigService) {
    super();
  }

}