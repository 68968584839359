import { Observable } from "rxjs";
import { ProcedimientoCamposModel, ProcedimientoModel, ProcedimientoPlantilla } from "../domain/procedimientos.model";

export abstract class ProcedimientoRepository {

   //abstractos procedimientos,//
  abstract getByPageProc(): Observable<ProcedimientoModel[]>
  abstract getDataProc(): Observable<ProcedimientoModel>
  abstract deleteProc(id: number): Observable<ProcedimientoModel>;
  abstract updateProc(id: number, data: Partial<ProcedimientoModel>): Observable<ProcedimientoModel>;
  abstract insertProc(data: Partial<ProcedimientoModel>): Observable<ProcedimientoModel>;
   
  
  //abstractos Campos, //
  abstract getByPageCampos(page: number, filter: string): Observable<ProcedimientoCamposModel>
  abstract getDataCampos(): Observable<any>
  abstract getDataCamposById(id:number): Observable<any>
  abstract deleteCampos(id: number): Observable<ProcedimientoCamposModel>;
  abstract updateCampos(id: number, data: Partial<ProcedimientoCamposModel>): Observable<ProcedimientoCamposModel>;
  abstract insertCampos(data: Partial<ProcedimientoCamposModel>): Observable<ProcedimientoCamposModel>;
  abstract getDataCamposProcedimiento(idProcedimiento:number): Observable<ProcedimientoCamposModel[]>


    //abstractos Plantillas, 3era tabla//
    abstract getByPagePlantilla(idProfesional:number): Observable<ProcedimientoPlantilla[]>
    abstract getPlantilla(): Observable<ProcedimientoPlantilla[]>
    abstract getDataPlantilla(): Observable<ProcedimientoPlantilla>
    abstract deletePlantilla(id: number): Observable<ProcedimientoPlantilla>;
    abstract updatePlantilla(id: number, data: Partial<ProcedimientoPlantilla>): Observable<ProcedimientoPlantilla>;
    abstract insertPlantilla(data: Partial<ProcedimientoPlantilla>): Observable<ProcedimientoPlantilla>;

}
    