import { HttpClient } from "@angular/common/http";
import { MarcaRepository } from "../application/marca.repository";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { Injectable } from "@angular/core";
import { MarcaModel } from "../domain/marca.model";
import { Observable } from "rxjs";

@Injectable()
export class MarcaInfraestructure extends MarcaRepository {
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  getData(): Observable<MarcaModel[]> {
    return this.http
      .get<MarcaModel[]>(`${this.config.appConfig.apiBaseUrl}/Marca`)
      .pipe();
  }

  delete(id: number): Observable<MarcaModel> {
    return this.http
      .delete<MarcaModel>(`${this.config.appConfig.apiBaseUrl}/Marca/${id}`)
      .pipe();
  }

  update(id: number, data: Partial<MarcaModel>): Observable<MarcaModel> {
    return this.http
      .put<MarcaModel>(`${this.config.appConfig.apiBaseUrl}/Marca/${id}`, data)
      .pipe();
  }

  insert(data: Partial<MarcaModel>): Observable<MarcaModel> {
    return this.http
      .post<MarcaModel>(`${this.config.appConfig.apiBaseUrl}/Marca`, data)
      .pipe();
  }
}
