import { Observable } from "rxjs";

export abstract class RecetaRepository{
   
    abstract getDataReceta(idConsultaProc:number): Observable<any>
    abstract getDataRecetaConsulta(idConsulta:number): Observable<any>
    abstract getDataRecetaId(id:number): Observable<any>
    abstract deleteReceta(id: number): Observable<any>;
    abstract updateReceta(id: number, data: Partial<any>): Observable<any>;
    abstract insertReceta(data: Partial<any>): Observable<any>; 
    
     
}