import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "./app.settings";
import { Settings } from "./app.settings.model";
import { LoaderService } from "./pages/helpers/services/loader.service";
import { FileService } from "./pages/helpers/services/file.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public settings: Settings;

  isLoading!: Observable<boolean>;

  constructor(
    public appSettings: AppSettings,
    private loaderService: LoaderService,
    private fileService: FileService,
    private titleService: Title
  ) {
    this.settings = this.appSettings.settings;
    this.fileService.getFileContent().subscribe((content) => {
      this.titleService.setTitle(content.nombreEmpresa);
     
    });
    this.isLoading = this.loaderService.httpLoader;

  }
  updatePageTitle(titulo: string): void {
    this.fileService.updateTitle(titulo);
  }

  ngOnInit() {}

}
