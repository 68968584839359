import { Observable } from "rxjs";
import { EspecialidadModel } from "../domain/especialidad.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";
import { AreaModel } from "../domain/area.model";

export abstract class EspecialidadRepository {
  abstract getByPage(): Observable<EspecialidadModel[]>;
  abstract getData(): Observable<EspecialidadModel[]>;
  abstract getByPrestacion(idPrestacion: number): Observable<EspecialidadModel[]>;
  abstract getAreas(idPrestacion: number): Observable<AreaModel[]>;
  abstract delete(id: number): Observable<EspecialidadModel>;

  abstract update(
    id: number,
    data: Partial<EspecialidadModel>
  ): Observable<EspecialidadModel>;

  abstract insert(
    data: Partial<EspecialidadModel>
  ): Observable<EspecialidadModel>;

  abstract insertAll(
    data: Partial<Array<EspecialidadModel>>
  ): Observable<EspecialidadModel>;

  abstract search(
    page: number,
    pageSize: number,
    filter: Filtro[]
  ): Observable<ReturnModelPaginado>;
}