import { Observable } from "rxjs";
import { FichaParametrica, FichaParametricaResponse } from "../domain/ficha-atencion.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";

export abstract class FichaParametricaRepository {
    abstract get(): Observable<FichaParametrica[]>;
    abstract getById(id: number): Observable<FichaParametrica>;
    abstract getFichaMedica(IdPrestacion: number, IdEspecialidad: number, conEspecialista: boolean): Observable<FichaParametrica>;
    abstract getFichaByConsulta(idConsulta: number): Observable<FichaParametrica>;
    abstract getGrilla(): Observable<FichaParametricaResponse[]>;
    abstract insert(ficha: Partial<FichaParametrica>): Observable<FichaParametrica>;
    abstract update(id:number, ficha: Partial<FichaParametrica>): Observable<FichaParametrica>;
    abstract clone(ficha: Partial<FichaParametrica>): Observable<FichaParametrica>;
    abstract SaveFichaConsulta(idConsulta: number, data: Partial<FichaParametrica>): Observable<FichaParametrica>;

    abstract search(
        page: number,
        pageSize: number,
        filter: Filtro[]
      ): Observable<ReturnModelPaginado>;
}