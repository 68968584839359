import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { EnvioCorreoRepository } from "../application/envio-correo.repository";
import { EnvioCorreoModel } from "../domain/envio-correo.model";

@Injectable()
export class EnvioCorreoInfraestructure extends EnvioCorreoRepository {

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(page: number, filter: string): Observable<EnvioCorreoModel>{
        return this.http.get<EnvioCorreoModel>(`${this.config.appConfig.apiBaseUrl}/EmailConf`).pipe();
    }

    getData(): Observable<EnvioCorreoModel>{
        return this.http.get<EnvioCorreoModel>(`${this.config.appConfig.apiBaseUrl}/EmailConf`).pipe();
    }

    delete(id: number): Observable<EnvioCorreoModel>{
        return this.http.delete<EnvioCorreoModel>(`${this.config.appConfig.apiBaseUrl}/EmailConf/${id}`).pipe()
    }

    update(id: number, data: Partial<EnvioCorreoModel>): Observable<EnvioCorreoModel>{
        return this.http.put<EnvioCorreoModel>(`${this.config.appConfig.apiBaseUrl}/EmailConf/${id}`,data).pipe()
    }

    insert(data: Partial<EnvioCorreoModel>): Observable<EnvioCorreoModel>{
        return this.http.post<EnvioCorreoModel>(`${this.config.appConfig.apiBaseUrl}/EmailConf`,data).pipe()
    }

}