import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { Injectable } from "@angular/core";
import { TipoPrestacionRepository } from "../application/tipo-prestacion.repository";
import { TipoPrestacionResponse } from "../domain/tipo-prestacion-response.model";
import { TipoPrestacionRequest } from "../domain/tipo-prestacion-request.model";
import { TipoPrestacionModel } from "../domain/tipo-prestacion.model";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";

@Injectable()
export class TipoPrestacionInfraestructure extends TipoPrestacionRepository {
    constructor(private http: HttpClient, private config: AppConfigService) {
        super();
    }

    get(): Observable<TipoPrestacionResponse[]> {
        return this.http.get<TipoPrestacionResponse[]>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion`).pipe();
    }

    getById(id: number): Observable<TipoPrestacionResponse> {
        return this.http.get<TipoPrestacionResponse>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion/${id}`).pipe();
    }

    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion/${id}`).pipe()
    }

    update(id: number, data: Partial<TipoPrestacionRequest>): Observable<TipoPrestacionResponse> {
        return this.http.put<TipoPrestacionResponse>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion/${id}`, data).pipe()
    }

    insert(data: Partial<TipoPrestacionRequest>): Observable<TipoPrestacionResponse> {
        return this.http.post<TipoPrestacionResponse>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion`, data).pipe()
    }

    insertAll(data: Partial<Array<TipoPrestacionRequest>>): Observable<TipoPrestacionModel> {
        return this.http.post<TipoPrestacionModel>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion/insertAll`, data).pipe()
    }

    search(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter));

        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/TipoPrestacion/search/${page}/${pageSize}`, { headers }).pipe();
    }
}