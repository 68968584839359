import { Component, Inject, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EstablecimientoRepository } from "src/app/pages/configuraciones/establecimiento/application/establecimiento.repository";
import { EstablecimientoModel } from "src/app/pages/configuraciones/establecimiento/domain/establecimiento.model";
import { LicenseService } from "src/app/pages/helpers/services/license.service";
import { NotificacionesService } from "src/app/pages/helpers/services/notificaciones.service";
import { SharedService } from "src/app/pages/helpers/services/shared.service";

@Component({
  selector: "app-liencia",
  templateUrl: "./liencia.component.html",
  styleUrls: ["./liencia.component.scss"],
})
export class LienciaComponent implements OnInit {
  public form: UntypedFormGroup;
  protected Establecimiento: EstablecimientoModel;
  constructor(
    private dialogRef: MatDialogRef<LienciaComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected _establecimiento: EstablecimientoRepository,
    private _lic: LicenseService,
    public fb: UntypedFormBuilder,
    public _notif: NotificacionesService,
    protected _shared: SharedService
  ) {
    this._establecimiento.getData().subscribe({
      next: (value) => {
        this.Establecimiento = value;
        console.log(value);
      },
      error: (err) => {},
    });
  }
  fGroup!: FormGroup;
  ngOnInit(): void {
    /*   this.fGroup = new FormGroup({
      controlLicencia: new FormControl(""),
    }); */

    this.form = this.fb.group({
      value: ["", Validators.compose([Validators.required])],
    });

  }

  save() {
    let est: EstablecimientoModel = {
      codigoInterno: this.form.controls["value"].value.toString(),
      id: 0,
    };

    if (this.Establecimiento) {
      this.Establecimiento.codigoInterno =
        this.form.controls["value"].value.toString();
    } else {
      this.Establecimiento = est;
    }


    this._lic.validarLicencia(this.Establecimiento).subscribe({
      next: (res) => {
    
        this._notif.mensajeExito(res, "check", 5);

        if (this.Establecimiento.id > 0) {
          this._establecimiento
            .update(this.Establecimiento.id, this.Establecimiento)
            .subscribe({
              next: (res) => {
                if (res) {
                  this.dialogRef.close(true);
                }
              },
              error: (err) => {},
            });
        } else {
          this._establecimiento.insert(this.Establecimiento).subscribe({
            next: (res) => {
              if (res) {
               
                this.dialogRef.close(true);
              }
            },
            error: (err) => {},
          });
        }
      },
      error: (err) => {
        console.log(err);
        this._notif.mensajeError(err.error, "error", 5);
      },
    });
  }
  exit() {
    this.dialogRef.close();
  }
}
