import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageRepository } from 'src/app/pages/core/application/storage.repository';

@Injectable({
  providedIn: 'root'
})
export class WatcherGuard implements CanActivate {

  constructor(private cache:StorageRepository){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const perfil = this.cache.obtenerDatoSesion('UC')
     
      if(perfil=='Administrativo'){
        return false;
      }else{
        return true;
      }
   
  }
  
}
