import { Observable } from "rxjs";
import { BiopsiaModel } from "../domain/biopsia.model";

export abstract class BiopsiaRepository {
    abstract getData(): Observable<BiopsiaModel>
    abstract delete(id: number): Observable<BiopsiaModel>;
    abstract update(id: number, data: Partial<BiopsiaModel>): Observable<BiopsiaModel>;
    abstract insert(data: Partial<BiopsiaModel>): Observable<BiopsiaModel>;
    abstract getDataId(id:number): Observable<BiopsiaModel>
    abstract descargarPDF(idBiopsia: number): Observable<any> 
    abstract descargarPDFEtiqueta(idBiopsia: number): Observable<any> 
    abstract getDataIdConsultaProc(idBiopsia:number): Observable<BiopsiaModel>
    }
    