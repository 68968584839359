import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfigService } from "../../helpers/services/app-config.service";
import { Filtro } from "../../helpers/services/filter.service";
import { ReturnModelPaginado } from "../../pacientes/domain/paciente.model";
import { ConsultaRepository } from "../application/consulta.repository";

@Injectable()
export class ConsultaInfraestructure extends ConsultaRepository {
 
  constructor(private http: HttpClient,private config:AppConfigService) {
    super();
  }

  getDataConsultaId(id: number): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/${id}`).pipe();
  }

  getByPageConsulta(
    page: number,
    pageSize: number,
    filter: Filtro[]
  ): Observable<any> {
    return this.http
      .get<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/${page}/${pageSize}`)
      .pipe();
  }
  getDataConsulta(): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/`).pipe();
  }
  deleteConsulta(id: number): Observable<any> {
    return this.http
      .delete<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/${id}`)
      .pipe();
  }
  updateConsulta(id: number, data: Partial<any>): Observable<any> {
    return this.http
      .put<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/${id}`, data)
      .pipe();
  }
  insertConsulta(data: Partial<any>): Observable<any> {
    return this.http.post<any>(`${this.config.appConfig.apiBaseUrl}/Consulta/`, data).pipe();
  }

  getPacientesConConsulta(
    page: number,
    pageSize: number,
    filter: Filtro[]
  ): Observable<ReturnModelPaginado> {
    let headers = new HttpHeaders().set("Filter", JSON.stringify(filter));
    return this.http
      .get<ReturnModelPaginado>(
        `${this.config.appConfig.apiBaseUrl}/Paciente/consulta/${page}/${pageSize}`,
        { headers }
      )
      .pipe();
  }

  insertDerivacion(data: Partial<any>): Observable<any> {
    return this.http
      .post<any>(`${this.config.appConfig.apiBaseUrl}/Derivacion/`, data)
      .pipe();
  }

  getByIdDerivacion(id: number): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/Derivacion/${id}`).pipe();
  }

  updateDerivacion(id: number, data: Partial<any>): Observable<any> {
    return this.http.put<any>(`${this.config.appConfig.apiBaseUrl}/Derivacion/${id}`,data).pipe();
  }


  getCie3(): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/CACHE/CIE3`).pipe();
  }

  getCie4(cie:string): Observable<any> {
    return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/CACHE/CIE4/${cie}`).pipe();
  }


}
