import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router, NavigationEnd } from "@angular/router";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { AppSettings } from "../app.settings";
import { Settings } from "../app.settings.model";
import { LienciaComponent } from "../shared/components/liencia/liencia.component";
import { MenuService } from "../theme/components/menu/menu.service";
import { AuthUseCase } from "./core/application/auth.usecase";
import { SidenavService } from "./helpers/services/sidenav.serivce";
import { UtilizacionService } from "./helpers/services/utilizacion.service";
import { UsoComponent } from "../shared/components/uso/uso/uso.component";
import { UsoRespository } from "../shared/components/uso/application/uso.repository";
import { SharedService } from "./helpers/services/shared.service";
import { StorageRepository } from "./core/application/storage.repository";
import { EstablecimientoRepository } from "./configuraciones/establecimiento/application/establecimiento.repository";
import { BiopsiaDisablerService } from "./helpers/services/biopsia-disabler.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  providers: [MenuService],
})
export class PagesComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;
  @ViewChild("backToTop") backToTop: any;
  @ViewChild("sidenavEnd") sidenavEnd: any;

  @ViewChildren(PerfectScrollbarDirective)
  pss: QueryList<PerfectScrollbarDirective>;
  public settings: Settings;
  public menus = ["vertical", "horizontal"];
  public menuOption: string;
  public menuTypes = ["default", "compact", "mini"];
  public menuTypeOption: string;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized

  numeroBadge: number = 0;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private auth: AuthUseCase,
    protected sidenavService: SidenavService,
    public dialog: MatDialog,
    protected _use : UtilizacionService,
    protected _uso: UsoRespository,
    protected _shared:SharedService,
    protected _storage:StorageRepository,
    protected _est:EstablecimientoRepository,
    protected _biop:BiopsiaDisablerService
    
  ) {
    this.settings = this.appSettings.settings;
    this._shared.isVisibleSource.subscribe((isVisible) => {
      this.obtenerUsados() // => true/false
  });
  }

 nombreUsuario:string

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = "vertical";
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }

    this.settings.sidenavedIsOpened = false;
    this.settings.sidenavedIsPinned = false;
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  
    this.settings.theme='red-light'
    

   this.obtenerUsados()


   this.nombreUsuario=this._storage.obtenerDatoSesion("NAM")
 

   this._biop.loadBiopsiaConfig()


  }

  onVoted(agreed: boolean) {
    
    if (agreed) {
     
    } else {
      
    }
  }

  obtenerUsados(){
    let number =0
    this._uso.obtenerUtilizaciones().subscribe({
      next: (res)=>{
       
        if(res){
          for(let i = 0; i < res.length; i++){
            if(!res[i].fechaFin){
              number = number+1
            }
           
          }
          this.numeroBadge=number
          this._use.setCantidadEnUso(this.numeroBadge)

        }
      },error:(err)=> {
        
      },
    })

   /*  let n:number=this._storage.obtenerDatoSesion("NC") */

   this._est.obtenerNumero().subscribe({
    next: (res)=>{
    

    this._shared.setnumeroVideo(res.cantidadVideo) 

    },error:(err)=> {
      
    },
   })

    
 
 
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.backToTop.nativeElement.style.display = "none";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(
        this.menuService.getVerticalMenuItems()
      );

    this.sidenavService.setSidenav(this.sidenav);
  }

  public isProcedimientosRoute() {
    let rout = this.router.url === "/examen";

    this.settings.sidenavedIsPinned = rout;
    this.settings.sidenavedIsOpened = rout;
    return rout;
  }
  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(["/"]);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  logout() {
    this.auth.logout();
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
    /*    this.sidenavService.toggle() */
  }
  public toggleSidenavEnd() {
    this.sidenavEnd.toggle();
  }

  public onPsScrollY(event) {
    event.target.scrollTop > 300
      ? (this.backToTop.nativeElement.style.display = "flex")
      : (this.backToTop.nativeElement.style.display = "none");
    if (this.settings.menu == "horizontal") {
      if (this.settings.fixedHeader) {
        var currentScrollTop =
          event.target.scrollTop > 56 ? event.target.scrollTop : 0;
        if (currentScrollTop > this.lastScrollTop) {
          document.querySelector("#horizontal-menu").classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document.querySelector("#horizontal-menu").classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
        this.lastScrollTop = currentScrollTop;
      } else {
        if (event.target.scrollTop > 56) {
          document.querySelector("#horizontal-menu").classList.add("sticky");
          event.target.classList.add("horizontal-menu-hidden");
        } else {
          document.querySelector("#horizontal-menu").classList.remove("sticky");
          event.target.classList.remove("horizontal-menu-hidden");
        }
      }
    }
  }

  public scrollToTop() {
    this.pss.forEach((ps) => {
      if (
        ps.elementRef.nativeElement.id == "main" ||
        ps.elementRef.nativeElement.id == "main-content"
      ) {
        ps.scrollToTop(0, 250);
      }
    });
  }

  @HostListener("window:resize")
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = "vertical";
    } else {
      this.defaultMenu == "horizontal"
        ? (this.settings.menu = "horizontal")
        : (this.settings.menu = "vertical");
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }

  openLicenceForm() {
    let ref = this.dialog.open(LienciaComponent);

    ref.afterClosed().subscribe({

      next:(value)=> {

        if(value){
          window.location.reload()
        }
        
      },
    })
  }
  openUseForm() {
    let ref = this.dialog.open(UsoComponent,{
      width:"70%"
    });

    ref.afterClosed().subscribe(x=>{
      this.obtenerUsados()
    })
  }

}
