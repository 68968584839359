import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfigService } from "../../helpers/services/app-config.service";
import { Filtro } from "../../helpers/services/filter.service";
import { PacienteRepository } from "../application/paciente.repository";
import { PacienteAgendaModel, PacienteModel, ReturnModelPaginado } from "../domain/paciente.model";
import { IdNombreArchivo } from "../../helpers/interfaces/pdfzip.interface";

@Injectable()
export class  PacienteInfraestructure extends PacienteRepository  {
   
    
   
 
    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    descargarPDfPacienteMasivo(listaIdsNombres: IdNombreArchivo[]): Observable<Blob> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

        return this.http.post<any>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/Pdfs/lista`,listaIdsNombres, {
            headers: headers,
            responseType: 'blob' as 'json', 
          }).pipe();
    }

    getByPagePaciente(page:number,pageSize:number, filter:Filtro[]): Observable<ReturnModelPaginado>{
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        //let Parametros  = new HttpParams().set("filter",filter)
        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Paciente/${page}/${pageSize}`,{headers}).pipe();
    }

    getByPagePacienteAgendaPrincipal(page:number,pageSize:number, filter:Filtro[],idUsuario:number): Observable<ReturnModelPaginado>{
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        //let Parametros  = new HttpParams().set("filter",filter)
        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Paciente/Agenda/Principal/${page}/${pageSize}/${idUsuario}`,{headers}).pipe();
    }

        getByPagePacienteAgenda(page:number,pageSize:number, filter:Filtro[]): Observable<ReturnModelPaginado>{
            let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
            //let Parametros  = new HttpParams().set("filter",filter)
            return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Paciente/Agenda/${page}/${pageSize}`,{headers}).pipe();
        }

        getHistorialPaciente(idPaciente:number,page: number, pageSize: number): Observable<ReturnModelPaginado> {
            return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Paciente/historial/${idPaciente}/${page}/${pageSize}`).pipe();
        }
       
      

        getDataPaciente(id:number): Observable<PacienteModel>{
            return this.http.get<PacienteModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/${id}`).pipe();
        }
        
        deletePaciente(id: number): Observable<PacienteModel>{
            return this.http.delete<PacienteModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/${id}`).pipe();
        }
        updatePaciente(id: number, data: Partial<PacienteModel>): Observable<PacienteModel>{
            return this.http.put<PacienteModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/${id}`,data).pipe();
        }
        insertPaciente(data: Partial<PacienteModel>): Observable<PacienteModel>{
            return this.http.post<PacienteModel>(`${this.config.appConfig.apiBaseUrl}/Paciente`,data).pipe();
        }

        getPacienteRut(rut: string): Observable<PacienteModel> {
            return this.http.get<PacienteModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/${rut}/single`).pipe();
        }

       getDataPacienteAgenda(idPacienteAgenda: number): Observable<PacienteAgendaModel> {
            return this.http.get<PacienteAgendaModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/Agenda/${idPacienteAgenda}`).pipe();
        }

        updatePacienteAgenda(id: number, data: Partial<PacienteAgendaModel>): Observable<PacienteAgendaModel>{
            return this.http.put<PacienteAgendaModel>(`${this.config.appConfig.apiBaseUrl}/Paciente/agenda/${id}`,data).pipe();
        }

    }