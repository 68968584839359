import { Observable } from "rxjs";
import { TipoPrestacionResponse } from "../domain/tipo-prestacion-response.model";
import { TipoPrestacionModel } from "../domain/tipo-prestacion.model";
import { TipoPrestacionRequest } from "../domain/tipo-prestacion-request.model";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";

export abstract class TipoPrestacionRepository {
  abstract get(): Observable<TipoPrestacionResponse[]>;
  abstract getById(id: number): Observable<TipoPrestacionResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract update(id: number,data: Partial<TipoPrestacionRequest>): Observable<TipoPrestacionModel>;
  abstract insert(data: Partial<TipoPrestacionRequest>): Observable<TipoPrestacionModel>;
  abstract insertAll(
    data: Partial<Array<TipoPrestacionRequest>>
  ): Observable<TipoPrestacionModel>;

  abstract search(
    page: number,
    pageSize: number,
    filter: Filtro[]
  ): Observable<ReturnModelPaginado>;
}