import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { TipoPagoRepository } from "../application/tipo-pago.repository";
import { TipoPago } from "../domain/tipo-pago.model";

@Injectable()
export class TipoPagoInfraestructure extends TipoPagoRepository{
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  getData(): Observable<TipoPago[]> {
    return this.http
      .get<TipoPago[]>(`${this.config.appConfig.apiBaseUrl}/TipoPago`)
      .pipe();
  }

  delete(id: number): Observable<TipoPago> {
    return this.http
      .delete<TipoPago>(`${this.config.appConfig.apiBaseUrl}/TipoPago/${id}`)
      .pipe();
  }

  update(id: number, data: Partial<TipoPago>): Observable<TipoPago> {
    return this.http
      .put<TipoPago>(`${this.config.appConfig.apiBaseUrl}/TipoPago/${id}`, data)
      .pipe();
  }

  insert(data: Partial<TipoPago>): Observable<TipoPago> {
    return this.http
      .post<TipoPago>(`${this.config.appConfig.apiBaseUrl}/TipoPago`, data)
      .pipe();
  }
}
