import { Observable } from "rxjs";
import { EspecialidadModel } from "../domain/especialidad.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { Injectable } from "@angular/core";
import { EspecialidadRepository } from "../application/especialidad.repository";
import { Filtro } from "src/app/pages/helpers/services/filter.service";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";
import { AreaModel } from "../domain/area.model";

@Injectable()
export class EspecialidadInfraestructure extends EspecialidadRepository {
    constructor(private http: HttpClient, private config: AppConfigService) {
        super();
    }

    getByPage(): Observable<EspecialidadModel[]> {
        return this.http.get<EspecialidadModel[]>(`${this.config.appConfig.apiBaseUrl}/Especialidad`).pipe();
    }

    getData(): Observable<EspecialidadModel[]> {
        return this.http.get<EspecialidadModel[]>(`${this.config.appConfig.apiBaseUrl}/Especialidad/combo`).pipe();
    }

    getByPrestacion(idPrestacion: number): Observable<EspecialidadModel[]> {
        return this.http.get<EspecialidadModel[]>(`${this.config.appConfig.apiBaseUrl}/Especialidad/byPrestacion/${idPrestacion}`).pipe();
    }

    getAreas(idPrestacion: number): Observable<AreaModel[]> {
        return this.http.get<AreaModel[]>(`${this.config.appConfig.apiBaseUrl}/Especialidad/getAreas/${idPrestacion}`).pipe();
    }

    delete(id: number): Observable<EspecialidadModel> {
        return this.http.delete<EspecialidadModel>(`${this.config.appConfig.apiBaseUrl}/Especialidad/${id}`).pipe()
    }

    update(id: number, data: Partial<EspecialidadModel>): Observable<EspecialidadModel> {
        return this.http.put<EspecialidadModel>(`${this.config.appConfig.apiBaseUrl}/Especialidad/${id}`, data).pipe()
    }

    insert(data: Partial<EspecialidadModel>): Observable<EspecialidadModel> {
        return this.http.post<EspecialidadModel>(`${this.config.appConfig.apiBaseUrl}/Especialidad`, data).pipe()
    }

    insertAll(data: Partial<Array<EspecialidadModel>>): Observable<EspecialidadModel> {
        return this.http.post<EspecialidadModel>(`${this.config.appConfig.apiBaseUrl}/Especialidad/insertAll`, data).pipe()
    }

    search(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter));

        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Especialidad/search/${page}/${pageSize}`, { headers }).pipe();
    }
}