import { Observable } from "rxjs";
import { CajaRepository } from "../application/caja.repository";
import { CajaDetalleModel } from "../domain/caja.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";

@Injectable()
  export class CajaInfraestructure extends CajaRepository {
   
    
    
    constructor(private http: HttpClient, private config: AppConfigService) {
        super();
      }
    
      getDataCajaDetalle(): Observable<CajaDetalleModel[]> {
        return this.http
          .get<CajaDetalleModel[]>(`${this.config.appConfig.apiBaseUrl}/CajaDetalle`)
          .pipe();
      }
    
      deleteCajaDetalle(id: number): Observable<CajaDetalleModel> {
        return this.http
          .delete<CajaDetalleModel>(`${this.config.appConfig.apiBaseUrl}/CajaDetalle/${id}`)
          .pipe();
      }
    
      updateCajaDetalle(id: number, data: Partial<CajaDetalleModel>): Observable<CajaDetalleModel> {
        return this.http
          .put<CajaDetalleModel>(`${this.config.appConfig.apiBaseUrl}/CajaDetalle/${id}`, data)
          .pipe();
      }
    
      insertCajaDetalle(data: Partial<CajaDetalleModel>): Observable<CajaDetalleModel> {
        return this.http
          .post<CajaDetalleModel>(`${this.config.appConfig.apiBaseUrl}/CajaDetalle`, data)
          .pipe();
      }

      getDataCajaDetalleId(idCajaDetalle: number): Observable<any> {
        return this.http
          .get<CajaDetalleModel>(`${this.config.appConfig.apiBaseUrl}/CajaDetalle/${idCajaDetalle}`)
          .pipe();
    }
   

    }