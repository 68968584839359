import { Observable } from "rxjs";
import { CajaDetalleModel } from "../domain/caja.model";

export abstract class CajaRepository {


    abstract getDataCajaDetalle(): Observable<any>

    abstract getDataCajaDetalleId(idCajaDetalle:number): Observable<any>

    abstract insertCajaDetalle(data: Partial<CajaDetalleModel>): Observable<any>;

   
    abstract updateCajaDetalle(id: number, data: Partial<CajaDetalleModel>): Observable<any>;


    abstract deleteCajaDetalle(id: number): Observable<CajaDetalleModel>;
     
 }