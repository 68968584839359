import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { EstablecimientoRepository } from "../application/establecimiento.repository";
import { EstablecimientoModel } from "../domain/establecimiento.model";

@Injectable()
export class EstablecimientoInfraestructure extends EstablecimientoRepository {
 

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(page: number, filter: string): Observable<EstablecimientoModel>{
        return this.http.get<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento`).pipe();
    }

    getData(): Observable<EstablecimientoModel>{
        return this.http.get<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento`).pipe();
    }

    delete(id: number): Observable<EstablecimientoModel>{
        return this.http.delete<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento/${id}`).pipe()
    }

    update(id: number, data: Partial<EstablecimientoModel>): Observable<EstablecimientoModel>{
        return this.http.put<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento/${id}`,data).pipe()
    }

    insert(data: Partial<EstablecimientoModel>): Observable<EstablecimientoModel>{
        return this.http.post<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento`,data).pipe()
    }
    obtenerNumero(): Observable<any> {
        return this.http.get<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento/numero/obtener`).pipe();
    }

    validarFecha(): Observable<any> {
        const headers= new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*');
        return this.http.get<EstablecimientoModel>(`${this.config.appConfig.apiBaseUrl}/Establecimiento/licencia/fecha`, {'headers':headers}).pipe();
    }

}