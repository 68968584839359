import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { MedicamentosRepository } from "../application/medicamentos.repository";
import { MedicamentosModel } from "../domain/medicamentos.model";

@Injectable()
export class MedicamentosInfraestructure extends MedicamentosRepository {

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(): Observable<MedicamentosModel[]>{
        return this.http.get<MedicamentosModel[]>(`${this.config.appConfig.apiBaseUrl}/Medicamento`).pipe();
    }

    getData(): Observable<MedicamentosModel>{
        return this.http.get<MedicamentosModel>(`${this.config.appConfig.apiBaseUrl}/Medicamento`).pipe();
    }

    delete(id: number): Observable<MedicamentosModel>{
        return this.http.delete<MedicamentosModel>(`${this.config.appConfig.apiBaseUrl}/Medicamento/${id}`).pipe()
    }

    update(id: number, data: Partial<MedicamentosModel>): Observable<MedicamentosModel>{
        return this.http.put<MedicamentosModel>(`${this.config.appConfig.apiBaseUrl}/Medicamento/${id}`,data).pipe()
    }

    insert(data: Partial<MedicamentosModel>): Observable<MedicamentosModel>{
        return this.http.post<MedicamentosModel>(`${this.config.appConfig.apiBaseUrl}/Medicamento`,data).pipe()
    }

}