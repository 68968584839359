import { Observable } from "rxjs";
import { captureSettings } from "../domain/captureSettings.model";

export abstract class CaptureSettingsRepository {
  abstract getByPage(page: number, filter: string): Observable<captureSettings>
  abstract getData(): Observable<captureSettings>
  /* abstract getByPageId(page: number, filter: string, id: number): Observable<EstablecimientoModel>; */
  abstract delete(id: number): Observable<captureSettings>;
  abstract update(id: number, data: Partial<captureSettings>): Observable<captureSettings>;
  abstract insert(data: Partial<captureSettings>): Observable<captureSettings>;
  }
  