import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { MenuService } from "../menu.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { StorageRepository } from "src/app/pages/core/application/storage.repository";
import { EstablecimientoRepository } from "src/app/pages/configuraciones/establecimiento/application/establecimiento.repository";
import { SharedService } from "src/app/pages/helpers/services/shared.service";

@Component({
  selector: "app-horizontal-menu",
  templateUrl: "./horizontal-menu.component.html",
  styleUrls: ["./horizontal-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit {
  @Input("menuParentId") menuParentId;
  public menuItems: Array<any>;
  public settings: Settings;
  private perf:any
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    private _est: EstablecimientoRepository,
    private _shared: SharedService,
    protected storage: StorageRepository
  ) {
    this.settings = this.appSettings.settings;
    this.perf=this.storage.obtenerDatoSesion("UC");
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();

    //ESTA ES LA QUE REALMENTE CAMBIA EL MENU

    this._est.obtenerNumero().subscribe({
      next: (res) => {
        if (res.tipoLicencia == 1) {
          // basica
          this.menuItems = [...this.menuItems].filter(
            (menuItem) =>
              menuItem.id != 19 && //generar agenda
              menuItem.id != 18 && //agenda
              menuItem.id != 3 && //consulta
              menuItem.id != 21 && // cargar agenda
              menuItem.id != 23 && // honorarios
              menuItem.id != 15 && // interoperabilidad
              menuItem.id != 22 // instituciones financieras
          );
        } else if (res.tipoLicencia == 3) {
          //full no agenda
          this.menuItems = [...this.menuItems].filter(
            (menuItem) =>
              menuItem.id != 19 && //generar agenda
              menuItem.id != 18 && //agenda
              menuItem.id != 21 && // cargar agenda
              menuItem.id != 23 && // honorarios
              menuItem.id != 15 && // interoperabilidad
              menuItem.id != 22 // instituciones financieras
          );
        }
      },
      error: (err) => {},
    });

    switch (this.perf) {
      case "Medico":
       
        break;
        case "Asistente":
          this.menuItems = [...this.menuItems].filter(
            (menuItem) =>
             
              menuItem.id != 3 
          );
        break;

        case "Administrativo":
          this.menuItems = [...this.menuItems].filter(
            (menuItem) =>
              menuItem.id != 2 && 
              menuItem.id != 3 && 
              menuItem.id != 7 && 
              menuItem.id != 8 && 
              menuItem.id != 9 && 
              menuItem.id != 20 && 
              menuItem.id != 13 && 
              menuItem.id != 10 &&
              menuItem.id != 14 && 
              menuItem.id != 11 && 
              menuItem.id != 12 && 
              menuItem.id != 13 && 
              menuItem.id != 20 && 
              menuItem.id != 19 && 
              menuItem.id != 21 && 
              menuItem.id != 17 && 
              menuItem.id != 15 && 
              menuItem.id != 22 && 
              menuItem.id != 23 
          );
        break;


    }
    

    this.menuItems = this.menuItems.filter(
      (item) => item.parentId == this.menuParentId
    );
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById("main-content");
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName(
            "mat-drawer-content"
          )[0].scrollTop = 0;
        }
      }
    });
  }
}
