import { Observable } from "rxjs";
import { EquiposModel } from "../domain/equipos.model";

export abstract class EquipoRepository {
    abstract getByPage(page?: number, filter?: string): Observable<EquiposModel[]>
    abstract getData(): Observable<EquiposModel>
    abstract delete(id: number): Observable<EquiposModel>;
    abstract update(id: number, data: Partial<EquiposModel>): Observable<EquiposModel>;
    abstract insert(data: Partial<EquiposModel>): Observable<EquiposModel>;
    abstract getEquipoProcedimiento(idProcedimiento:number): Observable<EquiposModel[]>
    }
    