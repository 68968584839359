import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstadisticaResponse, ReturnModelEstadistica } from "../../estadisticas/model/estadistica-return.model";
import { AppConfigService } from "../../helpers/services/app-config.service";
import { Filtro } from "../../helpers/services/filter.service";
import { PacienteModel, ReturnModelPaginado } from "../../pacientes/domain/paciente.model";
import { ProcedimientoConsultaRepository } from "../application/procedimiento-consulta.repository";
import { ConsultaCapturas, ProcedimientoConsultaModel } from "../domain/procedimiento-consulta.model";

@Injectable()
export class ProcedimientoConsultaInfraestructure extends ProcedimientoConsultaRepository {
    descargarImagenesMasivo(idConsultaProc: number): Observable<Blob> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

        return this.http.post<any>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/Respaldo/Imagenes/${idConsultaProc}`,{}, {
            headers: headers,
            responseType: 'blob' as 'json', 
          }).pipe();
    }

    
   

    getByIdAgenda(id: number): Observable<any> {
      return this.http.get<any>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/agenda/${id}`).pipe();
    }
   
   

    constructor(private http: HttpClient, private config:AppConfigService){
        super();
    }

    getById(id:number): Observable<ProcedimientoConsultaModel>{
        return this.http.get<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/${id}`).pipe();
    }

    getByPage(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelEstadistica>{
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        return this.http.get<ReturnModelEstadistica>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/${page}/${pageSize}`,{headers}).pipe();
    }

    getData(): Observable<ProcedimientoConsultaModel>{
        return this.http.get<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc`).pipe();
    }

    delete(id: number): Observable<ProcedimientoConsultaModel>{
        return this.http.delete<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/${id}`).pipe()
    }

    update(id: number, data: Partial<ProcedimientoConsultaModel>): Observable<ProcedimientoConsultaModel>{
        return this.http.put<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/${id}`,data).pipe()
    }

    insert(data: Partial<ProcedimientoConsultaModel>): Observable<ProcedimientoConsultaModel>{
        return this.http.post<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc`,data).pipe()
    }
    getPacientesConProcedimiento(page: number, pageSize: number, filter: Filtro[],idUsuario:number): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Paciente/procedimiento/${page}/${pageSize}/${idUsuario}`,{headers}).pipe();
    }   

    getPacientesConProcedimientoNoFiltro(idUsuario:number): Observable<any[]> {
        return this.http.get<any[]>(`${this.config.appConfig.apiBaseUrl}/Paciente/pacientes/${idUsuario}`,).pipe();
    }  

    enviarInformeProcedimiento(idConsultaProc: number, base: any): Observable<any> {
        return this.http.post<ProcedimientoConsultaModel>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/informe/enviar/${idConsultaProc}`,base).pipe()
    }

    insertCaptura(data: Partial<ConsultaCapturas>): Observable<ConsultaCapturas>{
        return this.http.post<ConsultaCapturas>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/InsertarCaptura`,data).pipe()
    }

    guardarVideoOrdenador(data: Partial<any>): Observable<any> {
        return this.http.post<ConsultaCapturas>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/GuardarVideoOrdenador`,data).pipe()
    }
    

    insertVideo(data: Partial<any>): Observable<any> {
        return this.http.post<ConsultaCapturas>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/InsertarVideo`,data).pipe()
    }
    insertCapturaOrdenador(data: Partial<ConsultaCapturas>): Observable<ConsultaCapturas>{
        return this.http.post<ConsultaCapturas>(`${this.config.appConfig.apiBaseUrl}/ConsultaProc/InsertarCapturaOrdenador`,data).pipe()
    }

}