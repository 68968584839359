import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { UsuarioRepository } from "../application/usuario.repository";
import { UsuarioModel, UsuarioResponse } from "../domain/usuario.model";

@Injectable()
export class UsuarioInfraestructure extends UsuarioRepository {
    constructor(private http: HttpClient, private config: AppConfigService) {
        super();
    }

    getByPage(page: number, filter: string): Observable<UsuarioModel> {
        throw new Error("Metodo no implementado.");
    }

    getData(): Observable<UsuarioResponse[]> {
        return this.http.get<UsuarioResponse[]>(`${this.config.appConfig.apiBaseUrl}/Usuario`).pipe();
    }

    delete(id: number): Observable<UsuarioModel> {
        return this.http.delete<UsuarioModel>(`${this.config.appConfig.apiBaseUrl}/Usuario/${id}`).pipe()
    }

    update(id: number, data: Partial<UsuarioModel>): Observable<UsuarioModel> {
        return this.http.put<UsuarioModel>(`${this.config.appConfig.apiBaseUrl}/Usuario/${id}`, data).pipe()
    }

    insert(data: Partial<UsuarioModel>): Observable<UsuarioModel> {
        return this.http.post<UsuarioModel>(`${this.config.appConfig.apiBaseUrl}/Usuario`, data).pipe()
    }

    getDataMedics(): Observable<UsuarioModel[]> {
        return this.http.get<UsuarioModel[]>(`${this.config.appConfig.apiBaseUrl}/Usuario/Medicos`).pipe();
    }

    getDataAuxiliares(): Observable<UsuarioModel[]> {
        return this.http.get<UsuarioModel[]>(`${this.config.appConfig.apiBaseUrl}/Usuario/Auxiliares`).pipe();
    }

    getById(id: number): Observable<any> {
        return this.http.get<UsuarioModel>(`${this.config.appConfig.apiBaseUrl}/Usuario/${id}`).pipe();
    }

    getByEspecialidad(idEspecialidad: number): Observable<UsuarioModel[]> {
        return this.http.get<UsuarioModel[]>(`${this.config.appConfig.apiBaseUrl}/Usuario/GetByEspecialidad/${idEspecialidad}`).pipe();
    }
}