import { Injectable } from "@angular/core"
import { CanLoad, Router } from "@angular/router"
import { AuthUseCase } from "src/app/pages/core/application/auth.usecase"

@Injectable({
    providedIn: "root",
  })
  export class AuthenticationGuard implements CanLoad {
    constructor(
      private auth: AuthUseCase,
      private router: Router,
    ) {}
    
    canActivate(): boolean {
      return this.auth.isAuthenticated()
    }
  
    canLoad(
    ):  boolean {
        return this.auth.isAuthenticated()
      }
  }