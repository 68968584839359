import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 

import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AppSettings } from './app.settings';

import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { AuthInfraestructure } from './pages/core/infraestructure/auth.infraestructure';
import { AuthRepository } from './pages/core/application/auth.repository';
import { AuthUseCase } from './pages/core/application/auth.usecase';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageRepository } from './pages/core/application/storage.repository';
import { StorageInfraestructure } from './pages/core/infraestructure/storage.infraestructure';
import { EstablecimientoRepository } from './pages/configuraciones/establecimiento/application/establecimiento.repository';
import { EstablecimientoInfraestructure } from './pages/configuraciones/establecimiento/infraestructure/establecimiento.infraestructure';
import { EquipoRepository } from './pages/configuraciones/equipos/application/equipo.repository';
import { EquipoInfraestructure } from './pages/configuraciones/equipos/infraestructure/equipo.infraestructure';
import { ProcedimientoRepository } from './pages/configuraciones/procedimientos/application/procedimientos.repository';
import { ProcedimientoInfraestructure } from './pages/configuraciones/procedimientos/infraestructure/procedimiento.infraestructure';
import { InterceptorTokenService } from './pages/helpers/services/interceptor-token.service';
import { UsuarioRepository } from './pages/usuarios/page-usuario/application/usuario.repository';
import { UsuarioInfraestructure } from './pages/usuarios/page-usuario/infraestructure/usuario.infraestructure';
import { EnvioCorreoRepository } from './pages/configuraciones/envio-correo/application/envio-correo.repository';
import { EnvioCorreoInfraestructure } from './pages/configuraciones/envio-correo/infraestructure/envio-correo.infraestructure';
import { PlanSaludRepository } from './pages/configuraciones/planes-salud/application/plan-salud.repository';
import { PlanSaludInfraestructure } from './pages/configuraciones/planes-salud/infraestructure/plan-salud.infraestructure';
import { PacienteRepository } from './pages/pacientes/application/paciente.repository';
import { PacienteInfraestructure } from './pages/pacientes/infraestructure/paciente.infraestructure';
import { ConsultaRepository } from './pages/consultas/application/consulta.repository';
import { ConsultaInfraestructure } from './pages/consultas/infraestructure/consulta.infraestructure';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { LoaderComponentComponent } from './shared/components/loader/loader-component/loader-component.component';
import { LoaderInterceptor } from './pages/helpers/interceptors/loader.interceptor';
import { MedicamentosRepository } from './pages/configuraciones/medicamentos/application/medicamentos.repository';
import { MedicamentosInfraestructure } from './pages/configuraciones/medicamentos/infraestructure/medicamentos.infraestructure';
import { InformeConfiguracionRepository } from './pages/configuraciones/informes/application/informe-config.repository';
import { InformeConfiguracionInfraestructure } from './pages/configuraciones/informes/infraestructure/informe-config.infraestructure';
import { SidenavedComponent } from './theme/components/sidenaved/sidenaved.component';
import { ProcedimientoConsultaRepository } from './pages/procedimientos/application/procedimiento-consulta.repository';
import { ProcedimientoConsultaInfraestructure } from './pages/procedimientos/infraestructure/procedimiento-consulta.infraestructure';
import { ReversePipe } from './theme/pipes/pipes/reverse.pipe';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacionesService } from './pages/helpers/services/notificaciones.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SidenavService } from './pages/helpers/services/sidenav.serivce';
import { RecetaRepository } from './pages/procedimientos/components/form-consulta-receta/application/receta.repository';
import { RecetaInfraestructure } from './pages/procedimientos/components/form-consulta-receta/infraestructure/receta.infrasestructure';
import { AppConfigService } from './pages/helpers/services/app-config.service';
import { CaptureSettingsRepository } from './pages/configuraciones/captura/application/capture-settings.repository';
import { CaptureSettingsInfraestructure } from './pages/configuraciones/captura/infraestructure/capture-settings.infraestructure';

import localeEs from '@angular/common/locales/es-419'
import { registerLocaleData } from '@angular/common';
import { EspecialidadRepository } from './pages/configuraciones/especialidad/application/especialidad.repository';
import { EspecialidadInfraestructure } from './pages/configuraciones/especialidad/infraestructure/especialidad.infraestructure';
import { UsoRespository } from './shared/components/uso/application/uso.repository';
import { UsoInfraestructure } from './shared/components/uso/infraestructure/uso.infraestructure';
import { PasswordRepository } from './shared/components/password/application/change-password.repositorty';
import { PasswordInfraestructure } from './shared/components/password/infraestructure/change-password.infraestructure';
import { MarcaRepository } from './pages/configuraciones/marcas/application/marca.repository';
import { MarcaInfraestructure } from './pages/configuraciones/marcas/infraestructure/marca.infraestructure';
import { CargarAgendaRepository } from './pages/configuraciones/cargar-agenda/application/cargar-agenda.repository';
import { CargarAgendaInfraestructure } from './pages/configuraciones/cargar-agenda/infraestructure/cargar-agenda.infraestructure';
import { TipoPagoRepository } from './pages/configuraciones/tipo-pago/application/tipo-pago.repository';
import { TipoPagoInfraestructure } from './pages/configuraciones/tipo-pago/infraestructure/tipo-pago.infraestructure';
import { CajaRepository } from './pages/pacientes/components/form-caja/application/caja.repository';
import { CajaInfraestructure } from './pages/pacientes/components/form-caja/infraestructure/caja.infraestructure';
import { InstitucionFinancieraRepository } from './pages/configuraciones/institucion-financiera/application/institucion-financiera.repository';
import { InstitucionFinancieraInfraestructure } from './pages/configuraciones/institucion-financiera/infraestructure/institucion-financiera.infraestructure';
import { HonorariosRepository } from './pages/configuraciones/honorarios/application/honrarios.repository';
import { HonorariosInfraestructure } from './pages/configuraciones/honorarios/infraestructure/honorarios.infraestructure';
import { BiopsiaRepository } from './pages/procedimientos/components/form-biopsia/application/bipsia.repository';
import { BiopsiaInfraestructure } from './pages/procedimientos/components/form-biopsia/infraestructure/biopsia.infraestructure';
import { PrestacionRepository } from './pages/configuraciones/prestaciones/application/prestaciones.repository';
import { PrestacionInfraestructure } from './pages/configuraciones/prestaciones/infraestructure/prestaciones.infraestructure';
import { TipoPrestacionRepository } from './pages/configuraciones/tipo-prestacion/application/tipo-prestacion.repository';
import { TipoPrestacionInfraestructure } from './pages/configuraciones/tipo-prestacion/infraestructure/tipo-prestacion.infraestructure';
import { DirectivesModule } from './theme/directives/directives.module';
import { FichaParametricaRepository } from './pages/configuraciones/ficha-atencion/application/ficha-paramet.repository';
import { FichaParametricaInfraestructure } from './pages/configuraciones/ficha-atencion/infraestructure/ficha-paramet.infraestructure';

registerLocaleData(localeEs)
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,     
    FormsModule, 
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }), 
    PerfectScrollbarModule, 
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SharedModule,
    PipesModule,
    DirectivesModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    LoaderComponentComponent,
    SidenavedComponent,
  ],
  providers: [ 
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: AuthRepository, useClass: AuthInfraestructure },AuthUseCase,
    { provide: StorageRepository, useClass: StorageInfraestructure },
    { provide: EstablecimientoRepository, useClass: EstablecimientoInfraestructure },
    { provide: EquipoRepository, useClass: EquipoInfraestructure },
    { provide: ProcedimientoRepository, useClass: ProcedimientoInfraestructure },
    { provide: UsuarioRepository, useClass: UsuarioInfraestructure },
    { provide: EnvioCorreoRepository, useClass: EnvioCorreoInfraestructure },
    { provide: PlanSaludRepository, useClass: PlanSaludInfraestructure },
    { provide: PacienteRepository, useClass: PacienteInfraestructure },
    { provide: ConsultaRepository, useClass: ConsultaInfraestructure },
    { provide: MedicamentosRepository, useClass: MedicamentosInfraestructure },
    { provide: InformeConfiguracionRepository, useClass: InformeConfiguracionInfraestructure },
    
    { provide: ProcedimientoConsultaRepository, useClass: ProcedimientoConsultaInfraestructure },
    { provide: CaptureSettingsRepository, useClass: CaptureSettingsInfraestructure },
    { provide: EspecialidadRepository, useClass: EspecialidadInfraestructure },
    { provide: UsoRespository, useClass: UsoInfraestructure },
    { provide: PasswordRepository, useClass: PasswordInfraestructure },
    { provide: MarcaRepository, useClass: MarcaInfraestructure },
    { provide: CargarAgendaRepository, useClass: CargarAgendaInfraestructure },
    { provide: TipoPagoRepository, useClass: TipoPagoInfraestructure },
    { provide: CajaRepository, useClass: CajaInfraestructure },
    
    { provide: HonorariosRepository, useClass: HonorariosInfraestructure },
    { provide: BiopsiaRepository, useClass: BiopsiaInfraestructure },
    { provide: InstitucionFinancieraRepository, useClass: InstitucionFinancieraInfraestructure },
    { provide: PrestacionRepository, useClass: PrestacionInfraestructure },
    { provide: TipoPrestacionRepository, useClass: TipoPrestacionInfraestructure },
    { provide: FichaParametricaRepository, useClass: FichaParametricaInfraestructure },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: InterceptorTokenService,
      multi   : true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
   },
    
    { provide: MAT_DATE_LOCALE, useValue: 'es-419'},
    {
      provide: LOCALE_ID, useValue:'es-419'
    },
    {
      provide:DatePipe
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA, useValue: {}
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    SidenavService,
    { provide: RecetaRepository, useClass: RecetaInfraestructure },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
          return () => {                    
              return appConfigService.loadAppConfig();
          };
      },
  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }