import { HttpClient } from "@angular/common/http";
import { SharedService } from "./shared.service";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/assets/app.config";
import { retry } from "rxjs";
import { StorageRepository } from "../../core/application/storage.repository";

@Injectable({
    providedIn: 'root'
  })

  export class BiopsiaDisablerService {

   
    constructor(private http: HttpClient, private shared:SharedService) {}
    
    loadBiopsiaConfig() {

   
    this.http
        .get<AppConfig>("../../../../assets/app.config.json")
        .pipe(
            retry(2), 
        )
        .subscribe(
            (data: AppConfig) => {
               
                this.shared.setBiopsia(data.usaBiopsia)
               
            },
            (error) => {
                // Failure
                console.log(error);
            }
        );
}

  }