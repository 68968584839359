import { Observable } from "rxjs";
import { InformeConfiguracionModel } from "../domain/informe.model";

export abstract class InformeConfiguracionRepository {
  abstract getByPage(page?: number, filter?: string): Observable<InformeConfiguracionModel[]>
  abstract getData(): Observable<InformeConfiguracionModel>
  abstract delete(id: number): Observable<InformeConfiguracionModel>;
  abstract update(id: number, data: Partial<InformeConfiguracionModel>): Observable<InformeConfiguracionModel>;
  abstract insert(data: Partial<InformeConfiguracionModel>): Observable<InformeConfiguracionModel>;
  }
  