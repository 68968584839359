import { Observable } from "rxjs";
import { CargarAgendaRepository } from "../application/cargar-agenda.repository";
import { CargarAgenda, CargarAgendaArchivo } from "../domain/carga-agenda.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { Injectable } from "@angular/core";
import { Filtro } from "src/app/pages/helpers/services/filter.service";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";

@Injectable()
export class CargarAgendaInfraestructure extends CargarAgendaRepository {
   

    
 
    deleteArchivo(id: number): Observable<CargarAgendaArchivo> {
        return this.http.delete<CargarAgendaArchivo>(`${this.config.appConfig.apiBaseUrl}/Agenda/Archivo/${id}`).pipe()
    }
    updateArchivo(id: number, data: Partial<CargarAgendaArchivo>): Observable<CargarAgendaArchivo> {
        return this.http.put<CargarAgendaArchivo>(`${this.config.appConfig.apiBaseUrl}/Agenda/Archivo/${id}`,data).pipe()
    }
    insertArchivo(data: Partial<CargarAgendaArchivo>): Observable<CargarAgendaArchivo> {
        return this.http.post<CargarAgendaArchivo>(`${this.config.appConfig.apiBaseUrl}/Agenda/Archivo`,data).pipe()
    }
    getByIdArchivo(id: number): Observable<CargarAgendaArchivo> {
        return this.http.get<CargarAgendaArchivo>(`${this.config.appConfig.apiBaseUrl}/Agenda/Archivo/${id}`).pipe();
    }

    getDataArchivo(): Observable<CargarAgendaArchivo>{
        return this.http.get<CargarAgendaArchivo>(`${this.config.appConfig.apiBaseUrl}/Agenda/Archivo`).pipe();
    }

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }
    
    getData(filter: Filtro[]): Observable<CargarAgenda>{
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        return this.http.get<CargarAgenda>(`${this.config.appConfig.apiBaseUrl}/Agenda`).pipe();
    }
    getById(id: number): Observable<CargarAgenda> {
        return this.http.get<CargarAgenda>(`${this.config.appConfig.apiBaseUrl}/Agenda/${id}`).pipe();
    }

    delete(id: number): Observable<CargarAgenda>{
        return this.http.delete<CargarAgenda>(`${this.config.appConfig.apiBaseUrl}/Agenda/${id}`).pipe();
    }

    update(id: number, data: Partial<CargarAgenda>): Observable<CargarAgenda>{
        return this.http.put<CargarAgenda>(`${this.config.appConfig.apiBaseUrl}/Agenda/${id}`,data).pipe();
    }

    insert(data: Partial<CargarAgenda>): Observable<CargarAgenda>{
        return this.http.post<CargarAgenda>(`${this.config.appConfig.apiBaseUrl}/Agenda`,data).pipe();
    }

    getByPageAgenda(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Agenda/Disponible/${page}/${pageSize}`,{headers}).pipe();
    }


    getByPageAgendaRango(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter))
        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/Agenda/Rango/${page}/${pageSize}`,{headers}).pipe();
    }

    

}