import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { AppConfig } from 'src/assets/app.config';



@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  appConfig: AppConfig;
  constructor(private http: HttpClient) {}
  
  loadAppConfig() {
      this.http
          .get<AppConfig>("../../../../assets/app.config.json")
          .pipe(
              retry(2), // Retry 3 times, if fails
          )
          .subscribe(
              (data: AppConfig) => {
                   // Success
                  this.appConfig = { ...data };
                 
              },
              (error) => {
                  // Failure
                  console.log(error);
              }
          );
}
}