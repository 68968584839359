import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { PlanSaludRepository } from "../application/plan-salud.repository";
import { PlanesSaludModel } from "../domain/planes-salud.model";

@Injectable()
export class  PlanSaludInfraestructure extends PlanSaludRepository  {
   
  
   
 
    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

        
        getByPagePlanSalud(): Observable<PlanesSaludModel[]>{
            return this.http.get<PlanesSaludModel[]>(`${this.config.appConfig.apiBaseUrl}/PlanSalud`).pipe();
        }
        getDataPlanSalud(): Observable<PlanesSaludModel>{
            return this.http.get<PlanesSaludModel>(`${this.config.appConfig.apiBaseUrl}/PlanSalud`).pipe();
        }

        getDataPlanSaludIdIndex(idProcedimiento: number, idPlan: number): Observable<PlanesSaludModel> {
            return this.http.get<PlanesSaludModel>(`${this.config.appConfig.apiBaseUrl}/PlanSalud/${idProcedimiento}/${idPlan}`).pipe();
        }
        deletePlanSalud(id: number): Observable<PlanesSaludModel>{
            return this.http.delete<PlanesSaludModel>(`${this.config.appConfig.apiBaseUrl}/PlanSalud/${id}`).pipe();
        }
        updatePlanSalud(id: number, data: Partial<PlanesSaludModel>): Observable<PlanesSaludModel>{
            return this.http.put<PlanesSaludModel>(`${this.config.appConfig.apiBaseUrl}/PlanSalud/${id}`,data).pipe();
        }
        insertPlanSalud(data: Partial<PlanesSaludModel>): Observable<PlanesSaludModel>{
            return this.http.post<PlanesSaludModel>(`${this.config.appConfig.apiBaseUrl}/PlanSalud`,data).pipe();
        }


    }