import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  isVisibleSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  numeroVideo: number;

  licenciaTipo:number

  data: any;

  consultaProc: any;

  pac: any;

  pacProc: boolean;

  modificable: boolean;

  modificableConsulta: boolean;
  pacienteConsulta: any;
  consulta: any;
  responseConsulta: any;
  biopsia:boolean

  constructor() {}

  //PROCEDIMIENTO CONSULKTA

  setBiopsia(boolean){
    this.biopsia=boolean
  }
  getBiopsia(){
    return this.biopsia;
  }

  setData(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  setPac(pac) {
    this.pac = pac;
  }

  getPac() {
    return this.pac;
  }

  setconsultaProc(consultaProc) {
    this.consultaProc = consultaProc;
  }

  getconsultaProc() {
    return this.consultaProc;
  }
  setPacProc(bool) {
    this.pacProc = bool;
  }

  getPacProc() {
    return this.pacProc;
  }

  setModificable(bool) {
    this.modificable = bool;
  }

  getModifcable() {
    return this.modificable;
  }

  //PROCEDIMIENTO CONSULKTA

  ////////RECETA////

  modificableReceta: boolean;

  setModificableReceta(bool) {
    this.modificableReceta = bool;
  }

  getModifcableReceta() {
    return this.modificableReceta;
  }

  //////RECETA//////
  //CONSULTA

  setModificableConsulta(bool) {
    this.modificableConsulta = bool;
  }

  getModifcableConsulta() {
    return this.modificableConsulta;
  }

  setDataConsulta(data) {
    this.consulta = data;
  }

  getDataConsulta() {
    return this.consulta;
  }
  setPacConsulta(bool) {
    this.pacienteConsulta = bool;
  }

  getPacConsulta() {
    return this.pacienteConsulta;
  }

  setRgesponseConsulta(data) {
    this.responseConsulta = data;
  }

  getResponseConsulta() {
    return this.responseConsulta;
  }

  //CONSULTA

  ///////////////FILTROS////////////////
  filtroPaciente: any;
  filtroConsulta: any;
  filtroUsuarios: any;
  filtroEstadisticas: any;
  filtroProcedimientos: any;

  setfiltroPaciente(value) {
    this.filtroPaciente = value;
  }

  getfiltroPaciente() {
    return this.filtroPaciente;
  }

  setfiltroProcedimiento(value) {
    this.filtroProcedimientos = value;
  }

  getfiltroProcedimiento() {
    return this.filtroProcedimientos;
  }
  setfiltroConsulta(value) {
    this.filtroConsulta = value;
  }

  getfiltroConsulta() {
    return this.filtroConsulta;
  }
  setfiltroUsuarios(value) {
    this.filtroUsuarios = value;
  }

  getfiltroUsuarios() {
    return this.filtroUsuarios;
  }
  setfiltroEstadisticas(value) {
    this.filtroEstadisticas = value;
  }

  getfiltroEstadisticas() {
    return this.filtroEstadisticas;
  }

  setnumeroVideo(data) {
    this.numeroVideo = data;
  }

  getnumeroVideo() {
    return this.numeroVideo;
  }

  licencia: any;

  setlicencia(data) {
    this.licencia = data;
  }

  getlicencia() {
    return this.licencia;
  }


  modificarLicencia:any

  setmodificarLicencia(data) {
    this.modificarLicencia = data;
  }

  getmodificarLicencia() {
    return this.modificarLicencia;
  }



  idUsuario:number
  setIdUsuario(number){
    this.idUsuario=number
  }

  getIdUsuario(){
    return this.idUsuario
  }

  setTipoLicencia(number){
    this.licenciaTipo=number
  }

  getTipoLicencia(){
    return this.licenciaTipo
  }



  fechaInicio
  fechaFin

  setFechaInicio(number){
    this.fechaInicio=number
  }

  getFechaInicio(){
    return this.fechaInicio
  }

  setFechaFin(number){
    this.fechaFin=number
  }

  getFechaFin(){
    return this.fechaFin
  }


cargado:number
  setCargad(number){
    this.cargado=number
  }

  getCargad(){
    return this.cargado
  }


}
