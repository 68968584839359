import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetectScrollDirective } from './detect-scroll/detect-scroll.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DetectScrollDirective,
    OnlyNumbersDirective
  ],
  exports: [
    DetectScrollDirective,
    OnlyNumbersDirective
  ]
})
export class DirectivesModule { }
