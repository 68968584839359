import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { captureSettings } from "../domain/captureSettings.model";
import { CaptureSettingsRepository } from "../application/capture-settings.repository";

@Injectable()
export class CaptureSettingsInfraestructure extends CaptureSettingsRepository {

    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(page: number, filter: string): Observable<captureSettings>{
        return this.http.get<captureSettings>(`${this.config.appConfig.apiBaseUrl}/CaptureSettings`).pipe();
    }

    getData(): Observable<captureSettings>{
        return this.http.get<captureSettings>(`${this.config.appConfig.apiBaseUrl}/CaptureSettings`).pipe();
    }

    delete(id: number): Observable<captureSettings>{
        return this.http.delete<captureSettings>(`${this.config.appConfig.apiBaseUrl}/CaptureSettings/${id}`).pipe()
    }

    update(id: number, data: Partial<captureSettings>): Observable<captureSettings>{
        return this.http.put<captureSettings>(`${this.config.appConfig.apiBaseUrl}/CaptureSettings/${id}`,data).pipe()
    }

    insert(data: Partial<captureSettings>): Observable<captureSettings>{
        return this.http.post<captureSettings>(`${this.config.appConfig.apiBaseUrl}/CaptureSettings`,data).pipe()
    }

}