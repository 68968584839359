import { Injectable } from "@angular/core";
import { UsoRespository } from "../application/uso.repository";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class UsoInfraestructure extends UsoRespository {
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  obtenerUtilizaciones(): Observable<any> {
    return this.http.get<any>(
      `${this.config.appConfig.apiBaseUrl}/Utilizacion`
    );
  }

  getUtilizacionId(id: number): Observable<any> {
    return this.http
      .get<any>(`${this.config.appConfig.apiBaseUrl}/Utilizacion/${id}`)
      .pipe();
  }

  deleteUtilizacion(id: number): Observable<any> {
    return this.http
      .delete<any>(`${this.config.appConfig.apiBaseUrl}/Utilizacion/${id}`)
      .pipe();
  }
  updateUtilizacion(id: number, data: Partial<any>): Observable<any> {
    return this.http
      .put<any>(`${this.config.appConfig.apiBaseUrl}/Utilizacion/${id}`, data)
      .pipe();
  }
  insertUtilizacion(data: Partial<any>): Observable<any> {
    return this.http
      .post<any>(`${this.config.appConfig.apiBaseUrl}/Utilizacion/`, data)
      .pipe();
  }
}
