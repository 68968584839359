import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EquiposModel } from "src/app/pages/configuraciones/equipos/domain/equipos.model";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { BiopsiaRepository } from "../application/bipsia.repository";
import { BiopsiaModel } from "../domain/biopsia.model";

@Injectable()
export class BiopsiaInfraestructure extends BiopsiaRepository {
    
    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    
    getData(): Observable<BiopsiaModel>{
        return this.http.get<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia`).pipe();
    }

    getDataId(id:number): Observable<BiopsiaModel>{
        return this.http.get<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia/${id}`).pipe();
    }

    getDataIdConsultaProc(id:number): Observable<BiopsiaModel>{
        return this.http.get<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia/ConsultaProc/${id}`).pipe();
    }


    delete(id: number): Observable<BiopsiaModel>{
        return this.http.delete<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia/${id}`).pipe()
    }

    update(id: number, data: Partial<BiopsiaModel>): Observable<BiopsiaModel>{
        return this.http.put<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia/${id}`,data).pipe()
    }

    insert(data: Partial<BiopsiaModel>): Observable<BiopsiaModel>{
        return this.http.post<BiopsiaModel>(`${this.config.appConfig.apiBaseUrl}/Biopsia`,data).pipe()
    }


    descargarPDF(idBiopsia: number): Observable<any> {
        return this.http.get<any>(
          `${this.config.appConfig.apiBaseUrl}/Biopsia/PDF/${idBiopsia}`
        );
      }

      descargarPDFEtiqueta(idBiopsia: number): Observable<any> {
        return this.http.get<any>(
          `${this.config.appConfig.apiBaseUrl}/Biopsia/PDF/Etiqueta/${idBiopsia}`
        );
      }

}