import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, delay, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Tokens } from "../../helpers/interfaces/tokens.interface";
import { AppConfigService } from "../../helpers/services/app-config.service";
import { AuthRepository } from "../application/auth.repository";
import { AuthModel } from "../domain/auth.model";

@Injectable()
export class AuthInfraestructure extends AuthRepository {
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  login(auth: AuthModel): Observable<Tokens> {
    return this.http
      .post<Tokens>(`${this.config.appConfig.apiBaseUrl}/auth/login`, auth)
      .pipe();
  }
}
