import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Pacientes', '/pacientes', null, 'personal_injury', null, false, 0),
    new Menu(2, 'Examen', '/examen', null, 'receipt', null, false, 0),
    new Menu(3, 'Consultas', '/consultas', null, 'edit_calendar', null, false, 0),
    new Menu(4, 'Usuarios', '/usuarios', null, 'group', null, false, 0),
    new Menu(5, 'Estadisticas', '/estadisticas', null, 'bar_chart', null, false, 0),
    new Menu(6, 'Configuraciones', '/configuraciones', null, 'settings', null, true, 0),

    new Menu(7, 'Establecimiento', '/configuraciones/establecimiento', null, '', null, false, 6),
    new Menu(8, 'Procedimientos', '/configuraciones/procedimientos', null, '', null, false, 6),
    new Menu(9, 'Equipos', '/configuraciones/equipos', null, '', null, false, 6),

    new Menu(13, 'Medicamentos', '/configuraciones/medicamentos', null, '', null, false, 6),
    new Menu(10, 'Informes', '/configuraciones/informes', null, '', null, false, 6),
    new Menu(14, 'Seleccion Captura', '/configuraciones/captura_seleccion', null, '', null, false, 6),
    new Menu(11, 'Envío Correo', '/configuraciones/envio_correo', null, '', null, false, 6),

    new Menu(12, 'Planes Salud', '/configuraciones/planes_salud', null, '', null, false, 6),
    new Menu(15, 'Interoperabilidad', '/configuraciones/interoperabilidad', null, '', null, false, 6),

    new Menu(16, 'Tipo Pago', '/configuraciones/tipo-pago', null, '', null, false, 6),

]

export const horizontalMenuItems = [
    new Menu(18, 'Agenda', '/agenda', null, 'event', null, false, 0),
    new Menu(1, 'Pacientes', '/pacientes', null, 'personal_injury', null, false, 0),
    new Menu(2, 'Examen', '/examen', null, 'receipt', null, false, 0),
    new Menu(3, 'Atenciones', '/consultas', null, 'edit_calendar', null, false, 0),
    new Menu(5, 'Reportes', '/reportes', null, 'bar_chart', null, false, 0),
    new Menu(6, 'Configuraciones', '/configuraciones', null, 'settings', null, true, 0),

    new Menu(7, 'Establecimiento', '/configuraciones/establecimiento', null, '', null, false, 6),

    new Menu(8, 'Procedimiento', '/configuraciones', null, '', null, true, 6),

    new Menu(8, 'Procedimiento', '/configuraciones/procedimientos', null, '', null, false, 8),
    new Menu(25, 'Ficha atención', '/configuraciones/ficha-atencion', null, '', null, false, 8),

    new Menu(9, 'Equipos', '/configuraciones/equipos', null, '', null, false, 6),
    new Menu(20, 'Marcas', '/configuraciones/marcas', null, '', null, false, 6),
    new Menu(13, 'Medicamentos', '/configuraciones/medicamentos', null, '', null, false, 6),
    new Menu(10, 'Informes', '/configuraciones/informes', null, '', null, false, 6),

    /* new Menu (24, 'Conf. Sol. Biopsia', '/configuraciones/solicitud-biopsia', null, '', null, false, 6), */
    new Menu(14, 'Seleccion Captura', '/configuraciones/captura_seleccion', null, '', null, false, 6),
    new Menu(11, 'Envío Correo', '/configuraciones/envio_correo', null, '', null, false, 6),
    new Menu(12, 'Planes Salud', '/configuraciones/planes_salud', null, '', null, false, 6),
    new Menu(13, 'Tipo Pago', '/configuraciones/tipo-pago', null, '', null, false, 6),
    new Menu(16, 'Usuarios', '/configuraciones/usuarios', null, '', null, false, 6),
    new Menu(19, 'Generar Agenda', '/configuraciones/generar_agenda', null, '', null, false, 6),
    new Menu(21, 'Cargar Agenda', '/configuraciones/consultar_agenda', null, '', null, false, 6),

    new Menu(24, 'Prestacion', '/configuraciones', null, '', null, true, 6),

    new Menu(15, 'Interoperabilidad', '/configuraciones/interoperabilidad', null, '', null, false, 6),
    new Menu(22, 'Instituciones Fin...', '/configuraciones/institucion-financiera', null, '', null, false, 6),
    new Menu(23, 'Honorarios', '/configuraciones/honorarios', null, '', null, false, 6),

    new Menu(17, 'Especialidad', '/configuraciones/especialidad', null, '', null, false, 24),
    new Menu(24, 'Prestacion', '/configuraciones/prestacion', null, '', null, false, 24),

    new Menu(26, 'Tipo de prestación', '/configuraciones/tipo-prestacion', null, '', null, false, 24)
    //26 ultimo id usado
]