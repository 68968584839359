import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[only-numbers]'
})
export class OnlyNumbersDirective {
  @HostBinding('autocomplete') public autocomplete;

  constructor() {
    this.autocomplete = 'off';
  }

  @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    return e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57)
  }

  @HostListener('input', ['$event'])
  onInput(e: any) {
    const value = e.target.value;

    if (!/^\d+$/.test(value)) {
      e.target.value = value.replace(/[^\d]/g, '');
    }
  }
}