import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { FichaParametricaRepository } from "../application/ficha-paramet.repository";
import { Observable } from "rxjs";
import { FichaParametrica, FichaParametricaResponse } from "../domain/ficha-atencion.model";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";

@Injectable()
export class FichaParametricaInfraestructure extends FichaParametricaRepository {
    constructor(private http: HttpClient, private config: AppConfigService) {
        super();
    }

    get(): Observable<FichaParametrica[]> {
        return this.http.get<FichaParametrica[]>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica`).pipe();
    }

    getGrilla(): Observable<FichaParametricaResponse[]> {
        return this.http.get<FichaParametricaResponse[]>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/GetGrilla`).pipe();
    }

    getById(id: number): Observable<FichaParametrica> {
        return this.http.get<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/${id}`).pipe();
    }

    getFichaMedica(IdPrestacion: number, IdEspecialidad: number, conEspecialista: boolean): Observable<FichaParametrica> {
        return this.http.get<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/GetFichaMedica/${IdPrestacion}/${IdEspecialidad}/${conEspecialista}`).pipe();
    }

    getFichaByConsulta(idConsulta: number): Observable<FichaParametrica> {
        return this.http.get<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/getFichaByConsulta/${idConsulta}`).pipe();
    }

    insert(data: Partial<FichaParametrica>): Observable<FichaParametrica> {
        return this.http.post<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica`, data).pipe()
    }

    update(id: number, data: Partial<FichaParametrica>): Observable<FichaParametrica> {
        return this.http.put<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/${id}`, data).pipe()
    }

    clone(data: Partial<FichaParametrica>): Observable<FichaParametrica> {
        return this.http.put<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/clone`, data).pipe()
    }

    SaveFichaConsulta(idConsulta: number, data: Partial<FichaParametrica>): Observable<FichaParametrica> {
        return this.http.put<FichaParametrica>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/SaveFichaConsulta/${idConsulta}`, data).pipe()
    }

    search(page: number, pageSize: number, filter: Filtro[]): Observable<ReturnModelPaginado> {
        let headers = new HttpHeaders().set("Filter", JSON.stringify(filter));

        return this.http.get<ReturnModelPaginado>(`${this.config.appConfig.apiBaseUrl}/FichaParametrica/search/${page}/${pageSize}`, { headers }).pipe();
    }
}