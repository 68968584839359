import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Forge from 'node-forge'

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(private http: HttpClient) { }

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC01af63srxx+Xx+BWD2Iw4OC2Z
  MSzzIrjCDIaTqRgj42YaWEJuyg4LBbBE0VImvGMcWs+zYQwIju6Dxfa7esj1zTMK
  BpQjML5/EzucaD/TJIuFZIRa6jmshiyQ3hXWFg3qx9cLCpzJy/6ddYW3tqseUG0u
  oqYk1LXdfARq6mRWCwIDAQAB
  -----END PUBLIC KEY-----`


encryptWithPublicKey(valueToEncrypt: string){
  const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
  return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
}
}
