import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MetaDataColumn } from "src/app/pages/helpers/interfaces/metaDataColumn.interface";
import { UtilizacionModel } from "../domain/use.model";
import { MatPaginator } from "@angular/material/paginator";
import { UsoRespository } from "../application/uso.repository";
import { DialogRef } from "@angular/cdk/dialog";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmComponent } from "../../confirm/confirm.component";

@Component({
  selector: "app-uso",
  templateUrl: "./uso.component.html",
  styleUrls: ["./uso.component.scss"],
})
export class UsoComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected dataSource = new MatTableDataSource<UtilizacionModel>();

  constructor(protected uso:UsoRespository, public dialog:MatDialog) {}
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.buscarDatos()

  }
  metaDataColumns: MetaDataColumn[] = [
    { field: "especialista", title: "especialista" },
    { field: "fechaInicio", title: "fechaInicio" },
    { field: "fechaFin", title: "fechaFin" },
    { field: "acciones", title: "acciones" },
  ];

  columnsProp: string[] = this.metaDataColumns.map(
    (column: any) => column.field
  );
  cargaInicialTablaDatos(data) {
    this.dataSource = new MatTableDataSource<UtilizacionModel>(data);
    this.dataSource.paginator = this.paginator;
  }

  buscarDatos(){
    this.uso.obtenerUtilizaciones().subscribe({
      next: (res)=>{
       /*  let a = res.sort((a, b) => a.fechaFin - b.fechaFin); */
       let a = res.filter(x=>x.fechaFin==null)
        
        this.cargaInicialTablaDatos(a)
      },error:(err)=> {
        
      },
    })
  }


  eliminar(row:any){
    let confirmComponent = this.dialog.open(ConfirmComponent, { data: row });

    confirmComponent.afterClosed().subscribe((response) => {
      if (response) {
        this.uso.deleteUtilizacion(row.id).subscribe({
          next: (res) => {
          

            this.buscarDatos()
          },
          error: (e) => {
           console.log(e)
         
          },
        });
      } else {
        return;
      }
    });

    
  }

 
}
