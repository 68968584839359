import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageRepository } from '../../core/application/storage.repository';

@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService implements HttpInterceptor  {

  constructor(protected _str: StorageRepository) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this._str.obtenerDatoSesion('tokeS')}`
      }
    });

    return next.handle(req);
  }
}
