import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationsComponent } from "src/app/shared/components/notifications/notifications.component";

@Injectable({
  providedIn: "root",
})
export class NotificacionesService {
  constructor(private _snackBar: MatSnackBar) {}

  mensajeError(topMessage,iconDisplayed, duration){
    this._snackBar.openFromComponent(NotificationsComponent, {
      data:{
        message: topMessage,
        icon:iconDisplayed
      },
      horizontalPosition:"center",
      verticalPosition:"bottom",
      panelClass:"error",
      duration: duration * 1000
    });
  }


  mensajeExito(topMessage,iconDisplayed, duration){
    this._snackBar.openFromComponent(NotificationsComponent, {
      data:{
        message: topMessage,
        icon:iconDisplayed
      },
      horizontalPosition:"center",
      verticalPosition:"bottom",
      panelClass:"success",
      duration: duration * 1000
    });
  }

  mensajeAlerta(topMessage,iconDisplayed, duration){
    this._snackBar.openFromComponent(NotificationsComponent, {
      data:{
        message: topMessage,
        icon:iconDisplayed
      },
      horizontalPosition:"center",
      verticalPosition:"bottom",
      panelClass:"alert",
      duration: duration * 1000
    });
  }

}
