import { Observable } from "rxjs";
import { UsuarioModel, UsuarioResponse } from "../domain/usuario.model";

export abstract class UsuarioRepository {
    abstract getByPage(page: number, filter: string): Observable<UsuarioModel>
    abstract getDataMedics(): Observable<UsuarioModel[]>
    abstract getDataAuxiliares(): Observable<UsuarioModel[]>
    abstract getData(): Observable<UsuarioResponse[]>
    abstract delete(id: number): Observable<UsuarioModel>;
    abstract update(id: number, data: Partial<UsuarioModel>): Observable<UsuarioModel>;
    abstract insert(data: Partial<UsuarioModel>): Observable<UsuarioModel>;
    abstract getById(id: number): Observable<UsuarioModel>;
    abstract getByEspecialidad(idEspecialidad: number): Observable<UsuarioModel[]>
}