import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { environment } from "src/environments/environment";
import { EquipoRepository } from "../application/equipo.repository";
import { EquiposModel } from "../domain/equipos.model";

@Injectable()
export class EquipoInfraestructure extends EquipoRepository {
    
    constructor(private http: HttpClient,private config:AppConfigService){
        super();
    }

    getByPage(page?: number, filter?: string): Observable<EquiposModel[]>{
        return this.http.get<EquiposModel[]>(`${this.config.appConfig.apiBaseUrl}/Equipo`).pipe();
    }

    getData(): Observable<EquiposModel>{
        return this.http.get<EquiposModel>(`${this.config.appConfig.apiBaseUrl}/Equipo`).pipe();
    }

    getEquipoProcedimiento(idProcedimiento: number): Observable<EquiposModel[]> {
        return this.http.get<EquiposModel[]>(`${this.config.appConfig.apiBaseUrl}/Equipo/${idProcedimiento}`).pipe();
    }


    delete(id: number): Observable<EquiposModel>{
        return this.http.delete<EquiposModel>(`${this.config.appConfig.apiBaseUrl}/Equipo/${id}`).pipe()
    }

    update(id: number, data: Partial<EquiposModel>): Observable<EquiposModel>{
        return this.http.put<EquiposModel>(`${this.config.appConfig.apiBaseUrl}/Equipo/${id}`,data).pipe()
    }

    insert(data: Partial<EquiposModel>): Observable<EquiposModel>{
        return this.http.post<EquiposModel>(`${this.config.appConfig.apiBaseUrl}/Equipo`,data).pipe()
    }

}