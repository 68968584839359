import { Observable } from "rxjs";
import { EstadisticaResponse, ReturnModelEstadistica } from "../../estadisticas/model/estadistica-return.model";
import { Filtro } from "../../helpers/services/filter.service";
import { PacienteModel, ReturnModelPaginado } from "../../pacientes/domain/paciente.model";
import { ConsultaCapturas, ProcedimientoConsultaModel } from "../domain/procedimiento-consulta.model";

export abstract class ProcedimientoConsultaRepository {
    abstract getById(id:number): Observable<ProcedimientoConsultaModel>
    abstract getByPage(page:number,pageSize:number,filter:Filtro[]): Observable<ReturnModelEstadistica>
    abstract getData(): Observable<ProcedimientoConsultaModel>
    abstract delete(id: number): Observable<ProcedimientoConsultaModel>;
    abstract update(id: number, data: Partial<ProcedimientoConsultaModel>): Observable<ProcedimientoConsultaModel>;
    abstract insert(data: Partial<ProcedimientoConsultaModel>): Observable<ProcedimientoConsultaModel>;
    abstract insertCaptura(data: Partial<ConsultaCapturas>): Observable<ConsultaCapturas>;
    abstract insertCapturaOrdenador(data: Partial<ConsultaCapturas>): Observable<ConsultaCapturas>
    abstract insertVideo(data: Partial<any>): Observable<any>;
    abstract getPacientesConProcedimiento(page:number,pageSize:number,filter:Filtro[],idUsuario:number): Observable<ReturnModelPaginado>;
    abstract getPacientesConProcedimientoNoFiltro(idUsuario:number): Observable<PacienteModel[]> ;
    abstract enviarInformeProcedimiento(idConsultaProc:number, base:any):Observable<any>
    abstract getByIdAgenda(id:number): Observable<any>
    abstract guardarVideoOrdenador(data: Partial<any>): Observable<any>;
    abstract descargarImagenesMasivo(idConsultaProc: number):Observable<Blob>
    }
    