import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { PagePacienteComponent } from './pages/pacientes/page-paciente/page-paciente.component';
import { AuthenticationGuard } from './shared/components/guards/authentication.guard';
import { WatcherGuard } from './shared/components/guards/watcher.guard';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            /* { path: 'pacientes', component: PagePacienteComponent, data: { breadcrumb: 'Pacientes' } },  */
            { path: '', loadChildren: () => import('./pages/pacientes/pacientes.module').then(m => m.PacientesModule), data: { breadcrumb: 'Pacientes' }, canActivate: [AuthenticationGuard] },
            { path: 'reportes', loadChildren: () => import('./pages/estadisticas/estadisticas.module').then(m => m.EstadisticasModule), data: { breadcrumb: 'Reportes' }, canActivate: [AuthenticationGuard] },
            { path: 'consultas', loadChildren: () => import('./pages/consultas/consultas.module').then(m => m.ConsultasModule), data: { breadcrumb: 'Consultas' }, canActivate: [AuthenticationGuard] },
            { path: 'examen', loadChildren: () => import('./pages/procedimientos/procedimientos.module').then(m => m.ProcedimientosModule), data: { breadcrumb: 'Examen' }, canActivate: [AuthenticationGuard] },
            { path: 'pacientes', loadChildren: () => import('./pages/pacientes/pacientes.module').then(m => m.PacientesModule), data: { breadcrumb: 'Pacientes' }, canActivate: [AuthenticationGuard] },
            { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule), data: { breadcrumb: 'Usuarios' }, canActivate: [WatcherGuard] },
            { path: 'configuraciones', loadChildren: () => import('./pages/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule), data: { breadcrumb: 'Configuraciones' }, canActivate: [AuthenticationGuard] },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' }, canActivate: [AuthenticationGuard] },
            { path: 'agenda', loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule), data: { breadcrumb: 'Agenda' }, canActivate: [AuthenticationGuard] },
        ],

        canLoad: [AuthenticationGuard],
    },
    { path: 'login', loadChildren: () => import('./pages/core/login/login.module').then(m => m.LoginModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }