import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EstablecimientoModel } from "../../configuraciones/establecimiento/domain/establecimiento.model";

@Injectable({
  providedIn: "root",
})
export class LicenseService {
  constructor(private http: HttpClient, private config: AppConfigService) {



  }


  validarLicencia(data:Partial<EstablecimientoModel>): Observable<any> {
    const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
   
    return this.http.post<any>(`${this.config.appConfig.apiBaseUrl}/Establecimiento/Licencia/Validar`,data,{'headers':headers}).pipe();
  }


}
