import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";
import { HonorariosRepository } from "../application/honrarios.repository";
import { HonorariosModel } from "../domain/honorarios.model";

@Injectable()
export class HonorariosInfraestructure extends HonorariosRepository{
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  getData(): Observable<HonorariosModel[]> {
    return this.http
      .get<HonorariosModel[]>(`${this.config.appConfig.apiBaseUrl}/Honorario`)
      .pipe();
  }

  delete(id: number): Observable<HonorariosModel> {
    return this.http
      .delete<HonorariosModel>(`${this.config.appConfig.apiBaseUrl}/Honorario/${id}`)
      .pipe();
  }

  update(id: number, data: Partial<HonorariosModel>): Observable<HonorariosModel> {
    return this.http
      .put<HonorariosModel>(`${this.config.appConfig.apiBaseUrl}/Honorario/${id}`, data)
      .pipe();
  }

  insert(data: Partial<HonorariosModel>): Observable<HonorariosModel> {
    return this.http
      .post<HonorariosModel>(`${this.config.appConfig.apiBaseUrl}/Honorario`, data)
      .pipe();
  }
}
