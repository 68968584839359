import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageRepository } from 'src/app/pages/core/application/storage.repository';
import { NotificacionesService } from 'src/app/pages/helpers/services/notificaciones.service';
import { PasswordRepository } from './application/change-password.repositorty';
import { EncryptionService } from 'src/app/pages/helpers/services/encryption.service';
import { UsuarioModel } from 'src/app/pages/usuarios/page-usuario/domain/usuario.model';
import { SharedService } from 'src/app/pages/helpers/services/shared.service';
import { UsuarioRepository } from 'src/app/pages/usuarios/page-usuario/application/usuario.repository';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

 private usuario : UsuarioModel
  togglePassword = true;
  fGroup!: FormGroup
  constructor(
    private _change:PasswordRepository,
    private _storage: StorageRepository,
    protected enc: EncryptionService,
    private reference: MatDialogRef<PasswordComponent>,
    protected notif:NotificacionesService,
    protected _shared:SharedService,
    protected _user:UsuarioRepository
    ) { 
    
  }

  ngOnInit(): void {
    this.fGroup = new FormGroup({
      controlPassUno : new FormControl,
      controlPassDos : new FormControl
    })

    let id = this._shared.getIdUsuario()
    console.log(id)

    this._user.getById(id).subscribe({
      next: (res)=>{

        this.usuario=res

      },error:(err)=> {
        
      },
    })


  }
 
  guardar(){

    let passs = this.fGroup.value

    

    if(passs.controlPassDos == passs.controlPassUno){
    
      let encPass = this.enc.encryptWithPublicKey(passs.controlPassDos) ; 

      this.usuario.claveTexto=encPass

      this._change.update(this.usuario?.id, this.usuario).subscribe({
        next: (upd)=>{
          this.reference.close(true)
          
        }
      })

  
    }else{
      this.notif.mensajeError("las contraseñas deben coincidir", "error",5)
    }

  }

}
