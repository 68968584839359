import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class FileService {
    constructor(private http: HttpClient) { }
  
    getFileContent(): Observable<any> {
      return this.http.get( "../../../../assets/app.config.json" , { responseType: 'json' });
    }
  
    tituloPagina: string = 'Default Title';
  
    updateTitle(newTitle: string): void {
      this.tituloPagina = newTitle;
    }
  }