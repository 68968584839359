import { Observable } from "rxjs";
import { EnvioCorreoModel } from "../domain/envio-correo.model";

export abstract class EnvioCorreoRepository {
    abstract getByPage(page: number, filter: string): Observable<EnvioCorreoModel>
    abstract getData(): Observable<EnvioCorreoModel>
    abstract delete(id: number): Observable<EnvioCorreoModel>;
    abstract update(id: number, data: Partial<EnvioCorreoModel>): Observable<EnvioCorreoModel>;
    abstract insert(data: Partial<EnvioCorreoModel>): Observable<EnvioCorreoModel>;
    }
    