import { Observable } from "rxjs";
import { CargarAgenda, CargarAgendaArchivo } from "../domain/carga-agenda.model";
import { Filtro } from "src/app/pages/helpers/services/filter.service";
import { ReturnModelPaginado } from "src/app/pages/pacientes/domain/paciente.model";

export abstract class CargarAgendaRepository {

    
    abstract getData(filter:Filtro[]): Observable<CargarAgenda>
    abstract getById( id: number): Observable<CargarAgenda>;
    abstract delete(id: number): Observable<CargarAgenda>;
    abstract update(id: number, data: Partial<CargarAgenda>): Observable<CargarAgenda>;
    abstract insert(data: Partial<CargarAgenda>): Observable<CargarAgenda>;

    abstract getDataArchivo(): Observable<CargarAgendaArchivo>
    abstract deleteArchivo(id: number): Observable<CargarAgendaArchivo>;
    abstract updateArchivo(id: number, data: Partial<CargarAgendaArchivo>): Observable<CargarAgendaArchivo>;
    abstract insertArchivo(data: Partial<CargarAgendaArchivo>): Observable<CargarAgendaArchivo>;
    abstract getByIdArchivo( id: number): Observable<CargarAgendaArchivo>
    
   abstract getByPageAgenda(page:number,pageSize:number,filter:Filtro[]): Observable<ReturnModelPaginado>
   
   abstract getByPageAgendaRango(page:number,pageSize:number,filter:Filtro[]): Observable<ReturnModelPaginado>

    }
    