import { Observable } from "rxjs";
import { PlanesSaludModel } from "../domain/planes-salud.model";

export abstract class PlanSaludRepository {

   abstract getByPagePlanSalud(): Observable<PlanesSaludModel[]>
   abstract getDataPlanSalud(): Observable<PlanesSaludModel>
   abstract getDataPlanSaludIdIndex(idProcedimiento:number, idPlan:number): Observable<PlanesSaludModel>
   abstract deletePlanSalud(id: number): Observable<PlanesSaludModel>;
   abstract updatePlanSalud(id: number, data: Partial<PlanesSaludModel>): Observable<PlanesSaludModel>;
   abstract insertPlanSalud(data: Partial<PlanesSaludModel>): Observable<PlanesSaludModel>;
}