import { Observable } from "rxjs";
import { EstablecimientoModel } from "../domain/establecimiento.model";

export abstract class EstablecimientoRepository {
  abstract getByPage(page: number, filter: string): Observable<EstablecimientoModel>
  abstract getData(): Observable<EstablecimientoModel>
  /* abstract getByPageId(page: number, filter: string, id: number): Observable<EstablecimientoModel>; */
  abstract delete(id: number): Observable<EstablecimientoModel>;
  abstract update(id: number, data: Partial<EstablecimientoModel>): Observable<EstablecimientoModel>;
  abstract insert(data: Partial<EstablecimientoModel>): Observable<EstablecimientoModel>;
  abstract obtenerNumero(): Observable<any>;
  abstract validarFecha(): Observable<any>;
  }
  