import { Observable } from "rxjs";
import { MedicamentosModel } from "../domain/medicamentos.model";

export abstract class MedicamentosRepository {
  abstract getByPage(
  ): Observable<MedicamentosModel[]>;
  abstract getData(): Observable<MedicamentosModel>;
  abstract delete(id: number): Observable<MedicamentosModel>;
  abstract update(
    id: number,
    data: Partial<MedicamentosModel>
  ): Observable<MedicamentosModel>;
  abstract insert(
    data: Partial<MedicamentosModel>
  ): Observable<MedicamentosModel>;
}
