import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { LienciaComponent } from "src/app/shared/components/liencia/liencia.component";
import { EstablecimientoRepository } from "../../configuraciones/establecimiento/application/establecimiento.repository";
import { Tokens } from "../../helpers/interfaces/tokens.interface";
import { NotificacionesService } from "../../helpers/services/notificaciones.service";
import { AuthModel } from "../domain/auth.model";
import { AuthRepository } from "./auth.repository";
import { StorageRepository } from "./storage.repository";
import { SharedService } from "../../helpers/services/shared.service";
import { PasswordComponent } from "src/app/shared/components/password/password.component";

@Injectable()
export class AuthUseCase {
  public settings: Settings;
  private userLogged = false;
  constructor(
    public appSettings: AppSettings,

    private repository: AuthRepository,
    private router: Router,
    private _notif: NotificacionesService,
    private storage: StorageRepository,
    protected _est: EstablecimientoRepository,
    private _dialog: MatDialog,
    protected _shared: SharedService
  ) {
    this.settings = this.appSettings.settings;
  }
  login(auth: AuthModel) {
    return this.repository.login(auth).subscribe({
      next: (response: Tokens) => {
      
        let a = response.cambia;
        this.userLogged = true;
        this._shared.setIdUsuario(response.i);
        this.storage.guardarDatoSesion(
          "NAM",
          response.nombre + " " + response.apPaterno + " " + response.apMaterno
        );
        this.storage.guardarDatoSesion("tokeS", response.token);
        this.storage.guardarDatoSesion("expiR", response.expiracion.toString());
        this.storage.guardarDatoSesion("UC", response.perfil);
        
        this.storage.guardarDatoSesion("Expi", response.i.toString());

        /* this.storage.setStorage("token", response.token);
        this.storage.setStorage("exp", response.expiracion.toString()); */
        this._est.getData().subscribe({
          next: (res) => {
        

            if (!res?.codigoInterno) {
              let ref = this._dialog.open(LienciaComponent);
              ref.afterClosed().subscribe({
                next: (res) => {
                  if (res) {
                    this._notif.mensajeExito("Licencia correcta", "check", 5);

                    if (a == false) {
                      let ref = this._dialog.open(PasswordComponent);

                      ref.afterClosed().subscribe({
                        next: (res) => {
                          if (res) {
                            this._notif.mensajeExito(
                              "Contraseña actualizada correctamente",
                              "check",
                              5
                            );
                            this.router.navigate(["/"]);
                          } else {
                            this.logout();
                          }
                        },
                      });
                    } else {
                      this.router.navigate(["/"]);
                    }
                  } else {
                    this.logout();
                  }
                },
              });
            } else {
              this._est.validarFecha().subscribe({
                next: (res) => {
                  
                  if (res) {
                    this._est.getData().subscribe({
                      next: (value) => {
                        this._shared.setlicencia(value);
                      },
                      error: (err) => {
                       
                      },
                    });

                    if (res == "Su licencia ha vencido") {
                      this._notif.mensajeError(res, "error", 8);
                      let ref = this._dialog.open(LienciaComponent);
                      ref.afterClosed().subscribe({
                        next: (res) => {
                          if (res) {
                            this._notif.mensajeExito(
                              "Licencia correcta",
                              "check",
                              5
                            );

                            if (a == false) {
                              let ref = this._dialog.open(PasswordComponent);

                              ref.afterClosed().subscribe({
                                next: (res) => {
                                  if (res) {
                                    this._notif.mensajeExito(
                                      "Contraseña actualizada correctamente",
                                      "success",
                                      5
                                    );
                                    this.router.navigate(["/"]);
                                  } else {
                                    this.logout();
                                  }
                                },
                              });
                            }
                            this.router.navigate(["/"]);
                          } else {
                            this.logout();
                          }
                        },
                      });
                    } else {
                      if (a == false) {
                        let ref = this._dialog.open(PasswordComponent);

                        ref.afterClosed().subscribe({
                          next: (res) => {
                            if (res) {
                              this._notif.mensajeExito(
                                "Contraseña actualizada correctamente",
                                "check",
                                5
                              );
                              this.router.navigate(["/"]);
                            } else {
                              this.logout();
                            }
                          },
                        });
                      } else {
                        this._notif.mensajeExito(res, "warning", 8);
                        this.router.navigate(["/"]);
                      }

                      /*  this._notif.mensajeExito(res, "warning", 8);
                      this.router.navigate(["/"]); */
                    }
                  }else{
                    this.router.navigate(["/"]);
                  }
                },
              });
              /* this.router.navigate(["/"]); */
            }
          },
          error: (err) => {},
        });
      },
      error: (e) => {
        this._notif.mensajeError(e.error, "error", 3);
      },
    });
  }

  logout() {
    this.storage.clearAllStorage(), (this.userLogged = false);
    this.router.navigate(["/login"]);
  }

  isAuthenticated(): boolean {
    /* if (sessionStorage.getItem("token") === null || undefined) { */
    let conf = this.storage.obtenerDatoSesion("tokeS");
  

    if (!conf) {
  
      this.router.navigateByUrl("/login");
      return false;
    } else {
      

     
      //console.log("AUTH POSITIVO")
      return true;
    }
  }
}
