import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/pages/helpers/services/app-config.service";

import { InstitucionFinancieraRepository } from "../application/institucion-financiera.repository";
import { InstitucionFinancieraModel } from "../domain/institucion-financiera.model";

@Injectable()
export class InstitucionFinancieraInfraestructure extends InstitucionFinancieraRepository{
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  getData(): Observable<InstitucionFinancieraModel[]> {
    return this.http
      .get<InstitucionFinancieraModel[]>(`${this.config.appConfig.apiBaseUrl}/InstitucionFinanciera`)
      .pipe();
  }

  delete(id: number): Observable<InstitucionFinancieraModel> {
    return this.http
      .delete<InstitucionFinancieraModel>(`${this.config.appConfig.apiBaseUrl}/InstitucionFinanciera/${id}`)
      .pipe();
  }

  update(id: number, data: Partial<InstitucionFinancieraModel>): Observable<InstitucionFinancieraModel> {
    return this.http
      .put<InstitucionFinancieraModel>(`${this.config.appConfig.apiBaseUrl}/InstitucionFinanciera/${id}`, data)
      .pipe();
  }

  insert(data: Partial<InstitucionFinancieraModel>): Observable<InstitucionFinancieraModel> {
    return this.http
      .post<InstitucionFinancieraModel>(`${this.config.appConfig.apiBaseUrl}/InstitucionFinanciera`, data)
      .pipe();
  }
}
