export abstract class StorageRepository {
  abstract setStorage(nameProperty: string, value: string): void;
  abstract getStorage(nameProperty: string): string | null;
  abstract clear(): void;
  abstract clearAllStorage(): void;
  abstract guardarDatoLocal(key: string, value: string) 
  abstract obtenerDatoLocal(key: string) 
  abstract removerDatoLocal(key: string) 
  abstract limpiarDatosLocal() 
  abstract guardarDatoSesion(key: string, value: string) 
  abstract obtenerDatoSesion(key: string) 
  abstract removerDatoSesion(key: string) 
  abstract limpiarDatosSesion() 
}
